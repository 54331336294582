.mc3-main-content {
  min-height: 700px;
  margin-bottom: 30px;
  .icon {
    vertical-align: middle;
  }
}
.mc3-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .mc3-content-title-end {
    text-align: right
  }
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0px;
    .form-control {
      height: 32px!important;
    }
  }
}

.has-background {
  .mc3-content-title {
    padding-top: 1rem;
  }
}
.mc3-row-carousel {
  .mc3-content-title {
    margin-bottom: 0;
  }
}

.mc3-icon-search {
  font-size: 60px;
}