@font-face {
  font-family: "OpenSans-Bold";
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Bold.ttf") format("truetype"),
    url("../fonts/OpenSans-Bold.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

@font-face {
  font-family: "OpenSans-Italic";
  src: url("../fonts/OpenSans-Italic.eot");
  src: url("../fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype"),
    url("../fonts/OpenSans-Italic.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype"),
    url("../fonts/OpenSans-Regular.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Light.ttf") format("truetype"),
    url("../fonts/OpenSans-Light.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype"),
    url("../fonts/OpenSans-SemiBold.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

@font-face {
  font-family: "Prata-Regular";
  src: url("../fonts/Prata-Regular.eot");
  src: url("../fonts/Prata-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Prata-Regular.ttf") format("truetype"),
    url("../fonts/Prata-Regular.woff") format("woff");
  -webkit-font-smoothing: [ auto];
}

/*font and size*/
@include mc3-t-x-list;
@include mc3-t-semibold-x-list;
@include mc3-t-bold-x-list;
@include mc3-t-light-x-list;
@include mc3-t-prata-x-list;

/*Style Text Color*/
.mc3-t-black {
  color: $black;
}

.mc3-t-white {
  color: $white;
}

.mc3-t-primary {
  color: $primary;
}

.mc3-t-default {
  color: $grey-t-default;
}

.mc3-t-invisible {
  color: $grey-t-invisible;
}

/*Text specific*/
.mc3-t-success {
  color: $success;
}

.mc3-t-active {
  color: $red-active;
}

.mc3-t-warning {
  color: $orange;
}

.mc3-t-danger {
  color: $red-noti;
}

.mc3-t-info {
  color: $info;
}

.mc3-t-pink {
  color: $pink;
}

/*Background color*/

.mc3-bg-primary {
  background-color: $primary;
}

.mc3-bg-success {
  background-color: $success;
}

.mc3-bg-warning {
  background-color: $orange;
}

.mc3-bg-noti {
  background-color: $red-noti;
}

.mc3-bg-invisible {
  background-color: $grey-t-invisible;
}

.mc3-bg-profile {
  background-color: $bg-profile;
}

/*typography*/
.mc3-flex-text-center {
  display: flex;
  align-items: center;
  text-align: center;
}

.mc3-flex-justify-center {
  display: flex;
  justify-content: center;
}

.mc3-flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mc3-flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.mc3-flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mc3-t-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

a.text-link {
  color: $primary;
}
