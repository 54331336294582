.mc3-table-menu {
  max-height: 300px;
  overflow-y: auto;
  background-color: $bg-info;
  padding: .5rem;
  .mc3-menu-header {
    border-bottom: 1px solid $bd-card;
  }
  .mc3-menu-content {
    margin-bottom: .5rem;
    .col-8 {
      @extend .mc3-t-overflow;
    }
    .col-4 {
      text-align: end;
    }
  }
}