.mc3-list-tags {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  margin-bottom: 0.5rem;
  .tags {
    display: flex;
    justify-content: start;
    margin: 0 0.5rem 0.5rem 0;
    .tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: $white;
      height: 20px;
      font-size: 10px;
      line-height: 1.5;
      padding: 0 10px;
      white-space: nowrap;
      &.tag-noti {
        background-color: $red-noti;
      }
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
    .icon-remove {
      position: relative;
      background-color: $red-noti;
      cursor: pointer;
      &::before,
      &::after {
        background-color: currentColor;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
      }
      &::before {
        height: 1px;
        width: 50%;
      }
      &::after {
        height: 50%;
        width: 1px;
      }
    }
  }
}