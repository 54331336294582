.mc3-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1rem auto;
  *:not(:first-child) {
    margin-left: 0.75rem;
  }
  .mc3-page-link {
    position: relative;
    display: block;
    padding: 0.3rem 0.55rem;
    line-height: 1.25;
    border: 1px solid $bd-input;
    border-radius: 1rem;
    &.disabled {
      color: $grey-t-invisible;
    pointer-events: none;
    cursor: auto;
    }
    &.prev-link,
    &.next-link{
      text-align: center;
      width: 80px;
    }
  }
  .mc3-page-link:hover,
  .mc3-page-link.active{
    color: $white;
    border: 1px solid $primary;
    background-color: $primary;
  }
}
@media (max-width: 576px) {
  .mc3-pagination {
    *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  .mc3-page-link.prev-link,
  .mc3-page-link.next-link {
    visibility: hidden;
    width: 40px!important;
    &::after {
      visibility: visible;
      position: absolute;
      padding: 0.3rem 0.75rem;
      line-height: 1.25;
      top: 0;
      left: 0;
      border: 1px solid $bd-input;
      border-radius: 1rem;
    }
    &:hover::after{
      background: $primary;
      border-color: $primary;
    }
  }
  .mc3-page-link.prev-link {
    &::after {
      content:'←';
    }
  }
  .mc3-page-link.next-link {
    &::after {
      content:'→';
    }
  }
}