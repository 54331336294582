.mc3-tab {
  .mc3-nav-full {
    width: 100%;
    border-bottom: 1px inset #e2e2e2;
    margin-bottom: 1rem;
  }
  .mc3-scroll-nav {
    width: 100%;
    margin-bottom: -1px;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y:hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .nav {
    display: block;
  }
  .nav-item {
    display: inline-block;
    min-width: 80px;
  }
  .nav-link {
    color: $grey-t-invisible;
    border-radius: 0;
    text-align: center;
    @media (max-width: 520px) {
      padding: 8px 10px;
    }
    &.active {
      background: $white;
      border-bottom: 3px solid $red-active;
      color: $grey-t-default;
    }
  }
}

