@charset "UTF-8";
/*Color*/
/*Color for text*/
/*Color for boder*/
/*Color for Background*/
/*
Position Advertisement
*/
/*
Box shadow
*/
/*
Item absolute center
*/
/*
Place holder
*/
/*
gradient banner black top for all browser
*/
/*Background Linear-Gradient*/
/*Regular is default*/
/*Define our mixin:*/
html, body {
  font-family: "OpenSans-Regular";
  color: #4a4a4a;
  scroll-behavior: smooth; }

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 6px;
  background: #e0e0e0; }

::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 6px; }

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.ttf") format("truetype"), url("../fonts/OpenSans-Bold.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

@font-face {
  font-family: "OpenSans-Italic";
  src: url("../fonts/OpenSans-Italic.eot");
  src: url("../fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Italic.ttf") format("truetype"), url("../fonts/OpenSans-Italic.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

@font-face {
  font-family: "OpenSans-Regular";
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.ttf") format("truetype"), url("../fonts/OpenSans-Regular.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

@font-face {
  font-family: "OpenSans-Light";
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.ttf") format("truetype"), url("../fonts/OpenSans-Light.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype"), url("../fonts/OpenSans-SemiBold.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

@font-face {
  font-family: "Prata-Regular";
  src: url("../fonts/Prata-Regular.eot");
  src: url("../fonts/Prata-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Prata-Regular.ttf") format("truetype"), url("../fonts/Prata-Regular.woff") format("woff");
  -webkit-font-smoothing: [auto]; }

/*font and size*/
.mc3-t-8 {
  font-size: 8px;
  font-family: OpenSans-Regular; }

.mc3-t-10, .mc3-card .card-footer {
  font-size: 10px;
  font-family: OpenSans-Regular; }

.mc3-t-12, .banner-tab-wrapper .banner-search .search-form-group .form-control {
  font-size: 12px;
  font-family: OpenSans-Regular; }

.mc3-t-14 {
  font-size: 14px;
  font-family: OpenSans-Regular; }

.mc3-t-16 {
  font-size: 16px;
  font-family: OpenSans-Regular; }

.mc3-t-18 {
  font-size: 18px;
  font-family: OpenSans-Regular; }

.mc3-t-20 {
  font-size: 20px;
  font-family: OpenSans-Regular; }

.mc3-t-22 {
  font-size: 22px;
  font-family: OpenSans-Regular; }

.mc3-t-24 {
  font-size: 24px;
  font-family: OpenSans-Regular; }

.mc3-t-26 {
  font-size: 26px;
  font-family: OpenSans-Regular; }

.mc3-t-28 {
  font-size: 28px;
  font-family: OpenSans-Regular; }

.mc3-t-30 {
  font-size: 30px;
  font-family: OpenSans-Regular; }

.mc3-t-32 {
  font-size: 32px;
  font-family: OpenSans-Regular; }

.mc3-t-semibold-8 {
  font-size: 8px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-10 {
  font-size: 10px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-12, .mc3-btn-show-more {
  font-size: 12px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-14, .banner-tab-wrapper .banner-tab .nav-link-custom {
  font-size: 14px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-16 {
  font-size: 16px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-18, .modal-body .mc3-popup-title {
  font-size: 18px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-20 {
  font-size: 20px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-22 {
  font-size: 22px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-24 {
  font-size: 24px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-26 {
  font-size: 26px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-28 {
  font-size: 28px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-30 {
  font-size: 30px;
  font-family: OpenSans-SemiBold; }

.mc3-t-semibold-32 {
  font-size: 32px;
  font-family: OpenSans-SemiBold; }

.mc3-t-bold-8 {
  font-size: 8px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-10 {
  font-size: 10px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-12 {
  font-size: 12px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-14 {
  font-size: 14px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-16 {
  font-size: 16px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-18 {
  font-size: 18px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-20 {
  font-size: 20px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-22 {
  font-size: 22px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-24 {
  font-size: 24px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-26 {
  font-size: 26px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-28 {
  font-size: 28px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-30 {
  font-size: 30px;
  font-family: OpenSans-Bold; }

.mc3-t-bold-32 {
  font-size: 32px;
  font-family: OpenSans-Bold; }

.mc3-t-light-8 {
  font-size: 8px;
  font-family: OpenSans-Light; }

.mc3-t-light-10 {
  font-size: 10px;
  font-family: OpenSans-Light; }

.mc3-t-light-12 {
  font-size: 12px;
  font-family: OpenSans-Light; }

.mc3-t-light-14 {
  font-size: 14px;
  font-family: OpenSans-Light; }

.mc3-t-light-16 {
  font-size: 16px;
  font-family: OpenSans-Light; }

.mc3-t-light-18 {
  font-size: 18px;
  font-family: OpenSans-Light; }

.mc3-t-light-20 {
  font-size: 20px;
  font-family: OpenSans-Light; }

.mc3-t-light-22 {
  font-size: 22px;
  font-family: OpenSans-Light; }

.mc3-t-light-24 {
  font-size: 24px;
  font-family: OpenSans-Light; }

.mc3-t-light-26 {
  font-size: 26px;
  font-family: OpenSans-Light; }

.mc3-t-light-28 {
  font-size: 28px;
  font-family: OpenSans-Light; }

.mc3-t-light-30 {
  font-size: 30px;
  font-family: OpenSans-Light; }

.mc3-t-light-32 {
  font-size: 32px;
  font-family: OpenSans-Light; }

.mc3-t-prata-8 {
  font-size: 8px;
  font-family: Prata-Regular; }

.mc3-t-prata-10 {
  font-size: 10px;
  font-family: Prata-Regular; }

.mc3-t-prata-12 {
  font-size: 12px;
  font-family: Prata-Regular; }

.mc3-t-prata-14 {
  font-size: 14px;
  font-family: Prata-Regular; }

.mc3-t-prata-16 {
  font-size: 16px;
  font-family: Prata-Regular; }

.mc3-t-prata-18 {
  font-size: 18px;
  font-family: Prata-Regular; }

.mc3-t-prata-20 {
  font-size: 20px;
  font-family: Prata-Regular; }

.mc3-t-prata-22 {
  font-size: 22px;
  font-family: Prata-Regular; }

.mc3-t-prata-24 {
  font-size: 24px;
  font-family: Prata-Regular; }

.mc3-t-prata-26 {
  font-size: 26px;
  font-family: Prata-Regular; }

.mc3-t-prata-28 {
  font-size: 28px;
  font-family: Prata-Regular; }

.mc3-t-prata-30 {
  font-size: 30px;
  font-family: Prata-Regular; }

.mc3-t-prata-32 {
  font-size: 32px;
  font-family: Prata-Regular; }

/*Style Text Color*/
.mc3-t-black {
  color: #000000; }

.mc3-t-white {
  color: #ffffff; }

.mc3-t-primary {
  color: #f7a433; }

.mc3-t-default {
  color: #4a4a4a; }

.mc3-t-invisible {
  color: #a6a6a6; }

/*Text specific*/
.mc3-t-success {
  color: #16bc4a; }

.mc3-t-active {
  color: #f2545b; }

.mc3-t-warning {
  color: #fd8c55; }

.mc3-t-danger {
  color: #FC3B3E; }

.mc3-t-info {
  color: #0f6fb3; }

.mc3-t-pink {
  color: #dd0687; }

/*Background color*/
.mc3-bg-primary {
  background-color: #f7a433; }

.mc3-bg-success {
  background-color: #16bc4a; }

.mc3-bg-warning {
  background-color: #fd8c55; }

.mc3-bg-noti {
  background-color: #FC3B3E; }

.mc3-bg-invisible {
  background-color: #a6a6a6; }

.mc3-bg-profile {
  background-color: #f9f9f9; }

/*typography*/
.mc3-flex-text-center {
  display: flex;
  align-items: center;
  text-align: center; }

.mc3-flex-justify-center {
  display: flex;
  justify-content: center; }

.mc3-flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.mc3-flex-justify-between {
  display: flex;
  justify-content: space-between; }

.mc3-flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.mc3-t-overflow, .mc3-card .card-footer .text-left, .mc3-card .card-footer .text-right, .mc3-card .card-footer .text-offer, .mc3-table-menu .mc3-menu-content .col-8 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

a,
a:hover {
  text-decoration: none;
  color: inherit; }

a.text-link {
  color: #f7a433; }

.navbar {
  height: 40px;
  padding: 0;
  background-color: #f4f4f4; }
  .navbar:focus {
    outline: none; }

.navbar-nav {
  width: 100%; }

@media screen and (min-width: 768px) {
  nav .nav .nav-item:hover .nav-link::before {
    position: absolute;
    content: "";
    display: block;
    background-color: #ffffff;
    left: calc(50% - 65px);
    width: 130px;
    height: 100%;
    top: 0px; }
  nav .nav .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px; }
    nav .nav .nav-link span {
      z-index: 1; }
  nav .nav .nav-md-sub {
    position: absolute;
    display: none;
    overflow: hidden;
    opacity: 0;
    height: 0;
    z-index: 2;
    left: 0;
    padding-left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.12); }
  nav .nav .nav-sub-item {
    margin: 1.5rem 1.5rem 1.5rem 0; } }

nav .nav .nav-item:active .nav-md-sub,
nav .nav .nav-item:hover .nav-md-sub {
  display: block;
  opacity: 1;
  height: auto;
  transition: all 0.3s ease-out; }

nav .nav .nav-link {
  font-size: 14px;
  color: #353535;
  cursor: pointer; }
  nav .nav .nav-link.first-nav {
    padding-left: 0px; }

nav .nav .nav-sub-title {
  color: #f7a433;
  font-weight: 600;
  margin-bottom: 6px; }

nav .nav .nav-sub-desc {
  font-size: 12px; }

nav .nav .form-control {
  height: 36px;
  width: 280px !important;
  font-size: 12px; }

nav .nav .mc3-btn {
  height: 34px;
  width: 100px; }

@media (max-width: 767.98px) {
  .navbar {
    height: auto;
    background-color: #ffffff; }
  .nav-sub-item {
    margin-bottom: 1rem;
    margin-right: 1rem; }
    .nav-sub-item .form-group {
      margin-bottom: 0; }
  .navbar-expand-md > .container {
    padding-right: 15px;
    padding-left: 15px; }
  .nav-md-sub {
    overflow: hidden;
    opacity: 0;
    height: 0;
    padding-left: 0; } }

/*This line fix bug on Safari*/
.row:before,
.row:after {
  display: none !important; }

[class^="col-"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.row {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

@media (min-width: 1200px) {
  .container {
    max-width: 960px; } }

.mc3-header {
  height: 60px; }
  .mc3-header .mc3-logo {
    background-image: url(../../assets/images/Me_Rewards.png);
    height: 2rem;
    min-width: 190px;
    background-size: 190px 32px;
    background-repeat: no-repeat; }
  .mc3-header .mc3-icon-search {
    margin-top: 4px;
    font-size: 20px;
    position: absolute;
    padding-left: 0.5rem; }
  .mc3-header .mc3-logo-circle {
    max-height: 2rem; }
  .mc3-header .imc-arrowdown {
    padding-left: 0.5rem;
    font-size: 0.5rem; }
  .mc3-header .nav-link {
    padding: 0.25rem 0.5rem;
    cursor: pointer; }
    .mc3-header .nav-link.last-nav {
      padding-right: 0px; }
  .mc3-header .dropdown-item {
    font-size: 0.75rem;
    padding: 6px 1rem;
    display: flex;
    align-items: center; }
    .mc3-header .dropdown-item:hover {
      background-color: #f7a433; }
    .mc3-header .dropdown-item:active {
      color: inherit; }
    .mc3-header .dropdown-item img {
      width: 32px;
      height: 32px;
      padding: 6px; }
    .mc3-header .dropdown-item .icon {
      padding-right: 0.5rem;
      font-size: 18px;
      line-height: 1; }
    .mc3-header .dropdown-item .mc3-mark-notification {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-family: OpenSans-Bold;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      color: #ffffff;
      background-color: #FC3B3E;
      margin-left: 10px; }
  .mc3-header .dropdown-menu {
    z-index: 1022;
    min-width: 180px;
    top: -1rem !important; }
    @media (max-width: 767.98px) {
      .mc3-header .dropdown-menu {
        transform: translate3d(-127px, 60px, 0px) !important; } }
    @media (min-width: 768px) {
      .mc3-header .dropdown-menu {
        left: 0; } }
  .mc3-header .mc3-vertical-divider {
    height: 1.5rem;
    overflow: hidden;
    border-left: 1px solid #e8e8e8; }
  .mc3-header .mc3-icon-left {
    margin-top: 10px;
    font-size: 20px; }
  .mc3-header .form-group {
    margin-bottom: 0px; }

.mc3-user-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.mc3-circle-icon {
  display: flex;
  justify-content: center;
  border: 0.5px solid #a6a6a6;
  width: 32px;
  height: 32px;
  margin: 10px 5px 10px 5px;
  border-radius: 50%;
  position: relative; }
  .mc3-circle-icon .mc3-icon-center {
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 20px; }
    .mc3-circle-icon .mc3-icon-center::before {
      line-height: 1.55; }
  .mc3-circle-icon .mc3-mark-notification {
    display: inline-flex;
    position: absolute;
    top: -4px;
    right: -4px;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #FC3B3E; }
  .mc3-circle-icon .mc3-dot {
    position: absolute;
    height: 10px;
    width: 10px;
    margin-left: 12px;
    margin-top: -4px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: #FC3B3E; }
  @media screen and (min-width: 0\0) {
    .mc3-circle-icon .mc3-dot {
      margin-left: 16px; } }
  @media screen and (min--moz-device-pixel-ratio: 0) {
    .mc3-circle-icon .mc3-dot {
      margin-left: 21px; } }

.mc3-icon-menuleft {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 0.5px solid #a6a6a6;
  width: 32px;
  height: 32px;
  margin: 10px 10px 10px 0px;
  border-radius: 50%; }

@media (max-width: 767.98px) {
  .mc3-search-box {
    display: flex;
    justify-content: flex-end; }
    .mc3-search-box .mc3-form-search {
      position: relative;
      display: flex;
      width: 50px;
      overflow: hidden;
      transition: 0.5s ease all; }
      .mc3-search-box .mc3-form-search.open {
        width: 100%; }
        .mc3-search-box .mc3-form-search.open .form-control {
          padding-left: 32px; }
    .mc3-search-box .form-control {
      height: 32px;
      padding-left: 22px;
      border-radius: 999px;
      font-size: 12px;
      border: 0.5px solid #a6a6a6;
      transition: all 0.5s; }
  .mc3-header .nav-link {
    padding: 0.25rem 0rem; }
  .mc3-header .mc3-logo {
    min-width: 168px;
    background-size: 168px 28px;
    background-position: center; } }

@media (min-width: 768px) {
  .mc3-search-box {
    display: flex;
    justify-content: normal;
    margin-left: 1.5rem !important; }
    .mc3-search-box .mc3-form-search {
      position: relative;
      display: flex;
      width: 100%; }
    .mc3-search-box .form-control {
      height: 32px;
      padding-left: 36px;
      border-radius: 999px;
      font-size: 12px;
      color: #4a4a4a;
      border: 0.5px solid #d4d4d4;
      width: 230px; }
      .mc3-search-box .form-control::-webkit-input-placeholder {
        color: #a6a6a6; }
      .mc3-search-box .form-control:-moz-placeholder {
        color: #a6a6a6; }
      .mc3-search-box .form-control::-moz-placeholder {
        color: #a6a6a6; }
      .mc3-search-box .form-control:-ms-input-placeholder {
        color: #a6a6a6; }
      .mc3-search-box .form-control:focus {
        color: #4a4a4a; }
    .mc3-search-box .mc3-icon-search {
      color: #4a4a4a; } }

.mc3-dropdown-icon-user {
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

footer {
  background-color: #243345; }
  footer .mc3-footer-page {
    color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem; }
  footer .mc3-footer-copyright {
    background-color: #ffffff; }
  footer .mc3-flex-justify-center {
    display: flex;
    justify-content: center; }
  footer .mc3-flex-text-center {
    display: flex;
    align-items: center;
    text-align: center; }

@media (min-width: 992px) {
  .ml-lg-8 {
    margin-left: 4rem !important; } }

.icon-app {
  height: 30px; }

.span-text-nowrap {
  white-space: nowrap; }

.mc3-main-content {
  min-height: 700px;
  margin-bottom: 30px; }
  .mc3-main-content .icon {
    vertical-align: middle; }

.mc3-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  .mc3-content-title .mc3-content-title-end {
    text-align: right; }
  .mc3-content-title [class^="mc3-t-"] {
    margin-bottom: 0; }
  .mc3-content-title .form-group {
    margin-bottom: 0px; }
    .mc3-content-title .form-group .form-control {
      height: 32px !important; }

.has-background .mc3-content-title {
  padding-top: 1rem; }

.mc3-row-carousel .mc3-content-title {
  margin-bottom: 0; }

.mc3-icon-search {
  font-size: 60px; }

.mc3-description-detail {
  word-break: break-word;
  line-height: 1.75;
  margin-bottom: 0.5rem; }
  .mc3-description-detail.collapse:not(.show) {
    display: block;
    max-height: 63px;
    overflow: hidden; }
  .mc3-description-detail.collapsing {
    height: 63px; }
  .mc3-description-detail a[href] {
    color: #f7a433; }

.mc3-btn-share:focus ~ .mc3-popup-share,
.mc3-popup-share:hover {
  display: block; }

.mc3-popup-share {
  position: absolute;
  right: 0;
  bottom: -55px;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  z-index: 30;
  display: none;
  -webkit-box-shadow: 0px 0px 10px #707070;
  -moz-box-shadow: 0px 0px 10px #707070;
  -o-box-shadow: 0px 0px 10px #707070;
  -ms-box-shadow: 0px 0px 10px #707070;
  box-shadow: 0px 0px 10px #707070; }
  .mc3-popup-share::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    background: #ffffff;
    transform: rotate(-45deg);
    top: -6px;
    right: 10px;
    z-index: -1;
    -webkit-box-shadow: 0px 0px 5px #707070;
    -moz-box-shadow: 0px 0px 5px #707070;
    -o-box-shadow: 0px 0px 5px #707070;
    -ms-box-shadow: 0px 0px 5px #707070;
    box-shadow: 0px 0px 5px #707070; }
  .mc3-popup-share ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: .5rem;
    height: 100%;
    background: #ffffff; }
    .mc3-popup-share ul li {
      margin: 0 5px; }
  .mc3-popup-share img {
    width: 30px;
    height: 30px; }

.mc3-card-send-a-love {
  width: 100%;
  height: 300px;
  background-position: center center;
  background-size: cover; }

/*Temp*/
article {
  line-height: 1.75; }
  article.has-widget {
    min-height: 500px; }
  article img {
    width: 100%; }
  article a[href] {
    color: #f7a433; }

.mc3-popup-share .social-share-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: .5rem;
  height: 100%;
  background: #fff; }
  .mc3-popup-share .social-share-button .ssb-icon {
    background-size: 30px 30px;
    height: 30px;
    width: 30px; }

.mc3-card-comment {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid #dddddd;
  overflow-y: auto;
  max-height: 400px; }
  .mc3-card-comment [class^="mc3-t-"] {
    margin-bottom: 0; }
  .mc3-card-comment .mc3-card-comment-header {
    margin-bottom: 1rem; }
    .mc3-card-comment .mc3-card-comment-header .mc3-card-comment-avatar {
      width: 40px;
      height: 40px;
      border-radius: 999px;
      background-position: center;
      background-size: contain;
      margin-right: .5rem; }

.mc3-author {
  margin-bottom: 1rem;
  max-height: 400px; }
  .mc3-author .mc3-author-header {
    margin-bottom: 0.5rem; }
    .mc3-author .mc3-author-header .mc3-author-avatar {
      width: 40px;
      height: 40px;
      border-radius: 999px;
      background-position: center;
      background-size: contain;
      margin-right: .5rem; }
  .mc3-author [class^="mc3-t-"] {
    margin-bottom: 0; }

.mc3-btn {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: #f7a433;
  outline: none;
  border: 1px solid transparent;
  color: #ffffff;
  font-size: 12px;
  position: relative;
  transition: all 0.5s ease;
  transform: translateY(0%);
  font-family: OpenSans-SemiBold;
  white-space: nowrap; }
  .mc3-btn:active {
    background-color: rgba(1, 174, 214, 0.2) !important; }
  .mc3-btn:hover {
    opacity: 0.6;
    color: #ffffff; }

.mc3-btn-outline, .mc3-btn-sm-outline, .mc3-btn-round-outline {
  background-color: #ffffff;
  border-color: #f7a433;
  color: #f7a433; }
  .mc3-btn-outline:hover, .mc3-btn-sm-outline:hover, .mc3-btn-round-outline:hover {
    opacity: 0.6;
    background-color: #f7a433;
    color: #ffffff; }

.mc3-btn-sm-outline {
  padding: 0px; }
  .mc3-btn-sm-outline .icon {
    font-size: 1rem;
    line-height: 1.95; }

.mc3-btn-round, .mc3-btn-round-outline, .mc3-btn-round-dark-outline {
  min-width: 80px;
  width: auto;
  height: 28px;
  border-radius: 100px; }

.mc3-btn-round-dark-outline {
  color: #4a4a4a;
  background-color: #ffffff;
  border-color: #4a4a4a; }

.mc3-button-group-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem; }
  .mc3-button-group-detail .mc3-button-group-detail-title {
    color: #f7a433;
    margin-bottom: .5rem;
    padding: 0 .5rem; }
  .mc3-button-group-detail .mc3-button-group-detail-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .5rem; }
    .mc3-button-group-detail .mc3-button-group-detail-btn *:not(:first-child) {
      margin-left: 10px; }
  .mc3-button-group-detail .mc3-btn {
    height: 28px;
    line-height: 1.3; }
  .mc3-button-group-detail .mc3-btn-sm-outline {
    width: 28px; }
  @media (max-width: 767px) {
    .mc3-button-group-detail .mc3-btn {
      width: 100%; }
      .mc3-button-group-detail .mc3-btn.mc3-btn-sm-outline {
        width: 28px; } }
  .mc3-button-group-detail .mc3-group-btn-share {
    position: relative; }

.mc3-btn-show-more {
  color: #f7a433; }
  .mc3-btn-show-more:hover {
    color: #f7a433; }
  .mc3-btn-show-more .icon {
    vertical-align: middle; }
  .mc3-btn-show-more[aria-expanded=true] > .more,
  .mc3-btn-show-more[aria-expanded=true] > .imc-angle-down {
    display: none; }
  .mc3-btn-show-more[aria-expanded=false] > .less,
  .mc3-btn-show-more[aria-expanded=false] > .imc-angle-up {
    display: none; }

a.btn, a.mc3-btn {
  cursor: pointer; }

.mc3-btn-flat {
  border: none;
  min-width: 160px;
  color: white;
  background-color: #f7a433; }
  .mc3-btn-flat::before {
    content: "Expand All"; }
  .mc3-btn-flat.active::before {
    content: "Collapse All"; }

@media (min-width: 992px) {
  .mc3-container {
    padding-left: 4rem;
    padding-right: 4rem; }
  .mc3-row-carousel {
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem; } }

@media (max-width: 991.98px) {
  .mc3-container {
    padding-left: 2rem;
    padding-right: 2rem; }
  .mc3-row-carousel {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem; } }

@media (max-width: 767.98px) {
  .mc3-container {
    padding-left: 1rem;
    padding-right: 1rem; }
  .mc3-row-carousel {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 575.98px) {
  .mc3-row-carousel {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }

.mc3-row-carousel {
  margin-bottom: 1rem; }
  .mc3-row-carousel.has-background {
    background-position: center center;
    background-size: cover;
    border-radius: 8px;
    padding-bottom: 0.5rem; }
  .mc3-row-carousel .mc3-card {
    margin: 0.5rem; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: 40%;
  -webkit-box-shadow: 0px 0px 5px #e0e0e0;
  -moz-box-shadow: 0px 0px 5px #e0e0e0;
  -o-box-shadow: 0px 0px 5px #e0e0e0;
  -ms-box-shadow: 0px 0px 5px #e0e0e0;
  box-shadow: 0px 0px 5px #e0e0e0; }
  .owl-carousel .owl-nav button.owl-prev.disabled,
  .owl-carousel .owl-nav button.owl-next.disabled {
    display: none; }
  .owl-carousel .owl-nav button.owl-prev:focus, .owl-carousel .owl-nav button.owl-prev:hover,
  .owl-carousel .owl-nav button.owl-next:focus,
  .owl-carousel .owl-nav button.owl-next:hover {
    outline: none;
    -webkit-box-shadow: 0px 0px 5px #f7a433;
    -moz-box-shadow: 0px 0px 5px #f7a433;
    -o-box-shadow: 0px 0px 5px #f7a433;
    -ms-box-shadow: 0px 0px 5px #f7a433;
    box-shadow: 0px 0px 5px #f7a433;
    transition: all 0.5s ease; }

.owl-carousel .owl-nav button.owl-prev {
  left: -40px; }
  .owl-carousel .owl-nav button.owl-prev .mc3-icon-carousel {
    margin-left: 15px;
    border-left: 1.5px solid #a6a6a6;
    border-bottom: 1.5px solid #a6a6a6; }
  .owl-carousel .owl-nav button.owl-prev:focus .mc3-icon-carousel, .owl-carousel .owl-nav button.owl-prev:hover .mc3-icon-carousel {
    border-left: 1.5px solid #f7a433;
    border-bottom: 1.5px solid #f7a433; }

.owl-carousel .owl-nav button.owl-next {
  right: -40px; }
  .owl-carousel .owl-nav button.owl-next .mc3-icon-carousel {
    margin-left: 10px;
    border-right: 1.5px solid #a6a6a6;
    border-top: 1.5px solid #a6a6a6; }
  .owl-carousel .owl-nav button.owl-next:focus .mc3-icon-carousel, .owl-carousel .owl-nav button.owl-next:hover .mc3-icon-carousel {
    border-right: 1.5px solid #f7a433;
    border-top: 1.5px solid #f7a433; }

.owl-carousel .owl-nav .mc3-icon-carousel {
  width: 12px;
  height: 12px;
  transform: rotate(45deg); }

.mc3-carousel-detail-lg {
  width: 100%; }
  .mc3-carousel-detail-lg .mc3-slider-main-item {
    width: 100%;
    padding-top: 57.14286%;
    background-position: center center;
    background-size: cover; }
  .mc3-carousel-detail-lg .mc3-slider-select-item,
  .mc3-carousel-detail-lg .mc3-slider-select-item.slick-slide {
    width: 100%;
    height: 64px;
    background-position: center center;
    background-size: cover; }

.mc3-carousel-detail-sm {
  width: 100%; }
  .mc3-carousel-detail-sm .mc3-slider-main-item {
    width: 100%;
    padding-top: 57.14286%;
    background-position: center center;
    background-size: cover; }
  .mc3-carousel-detail-sm .mc3-slider-select-item,
  .mc3-carousel-detail-sm .mc3-slider-select-item.slick-slide {
    width: 100%;
    height: 54px;
    background-position: center center;
    background-size: cover; }

.slider-nav .slick-list {
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 5px; }
  .slider-nav .slick-list::-webkit-scrollbar {
    height: 5px; }
  .slider-nav .slick-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e8e8e8;
    border-radius: 999px;
    background: #ffffff; }
  .slider-nav .slick-list::-webkit-scrollbar-thumb {
    background: #c5c9d3;
    border-radius: 6px; }

.slider-nav-sm .slick-slide,
.slider-nav-lg .slick-slide {
  cursor: pointer;
  margin: 8px 8px 0 0; }
  .slider-nav-sm .slick-slide.is-active,
  .slider-nav-lg .slick-slide.is-active {
    border-bottom: 3px solid #f7a433; }

.slick-next {
  right: 0.75rem;
  z-index: 2;
  width: unset;
  height: unset; }
  .slick-next:before {
    font-family: "mc-icon";
    font-size: 20px;
    content: "\4c"; }

.slick-prev {
  left: 0.75rem;
  z-index: 2;
  width: unset;
  height: unset; }
  .slick-prev:before {
    font-family: "mc-icon";
    font-size: 20px;
    content: "\4d"; }

.slick-slider .slick-disabled {
  display: none !important; }

.mc3-card-group [class^="col-"] {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

.mc3-card-horizontal {
  width: 100%; }
  .mc3-card-horizontal .mc3-horizontal-img {
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    padding-top: 272px; }
  @media (min-width: 768px) {
    .mc3-card-horizontal .mc3-card-horizontal-image {
      padding-right: 0px; }
    .mc3-card-horizontal .mc3-card-horizontal-info {
      padding-left: 0px; } }
  .mc3-card-horizontal .card-body {
    padding: 1rem 0.5rem; }
  .mc3-card-horizontal .card-title {
    font-family: "Prata-Regular";
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 1rem;
    /*For Chrome*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical; }
  .mc3-card-horizontal .card-text {
    line-height: 1.75;
    /*For Chrome*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    -webkit-box-orient: vertical; }
  @media (min-width: 768px) {
    .mc3-card-horizontal .card-text {
      height: 96px; }
    .mc3-card-horizontal .card-title {
      height: 108px; } }
  @media (max-width: 767.98px) {
    .mc3-card-horizontal .card-text {
      max-height: 96px; }
    .mc3-card-horizontal .card-title {
      max-height: 108px; } }
  .mc3-card-horizontal .mc3-arrow-floating {
    background-color: #ffffff;
    margin-left: -10px;
    margin-right: -10px;
    width: 20px;
    height: 20px;
    margin-top: 20%;
    transform: rotate(45deg); }

.mc3-card.has-shadow {
  -webkit-box-shadow: 0px 0px 5px #e0e0e0;
  -moz-box-shadow: 0px 0px 5px #e0e0e0;
  -o-box-shadow: 0px 0px 5px #e0e0e0;
  -ms-box-shadow: 0px 0px 5px #e0e0e0;
  box-shadow: 0px 0px 5px #e0e0e0;
  border: none; }

.mc3-card.highlight {
  overflow: hidden;
  border: 2px solid #f7a433; }

.mc3-card .card-body {
  padding: 0.5rem; }

.mc3-card .card-body.fixed-height {
  height: 88px; }

.mc3-card .mc3-card-img {
  position: relative;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  height: 0;
  padding-top: 57.14286%;
  border-radius: 1px 1px 0px 0px; }
  .mc3-card .mc3-card-img .icon-remove {
    position: absolute;
    top: 1px;
    right: 0.25rem; }

.mc3-card .mc3-card-title {
  margin-bottom: 0.25rem;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mc3-card .mc3-card-title-long {
  font-family: "Prata-Regular";
  font-size: 1rem;
  line-height: 1.5;
  height: 72px;
  margin-bottom: 0px;
  /*Temp*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical; }

.mc3-card .card-sub-title {
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mc3-card .card-content {
  color: #707070;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mc3-card .card-footer {
  background-color: #ffffff;
  padding: 0.5rem;
  border-top: 1px solid #f5f5f5; }
  .mc3-card .card-footer .mc3-icon-card {
    float: left;
    font-size: 1rem;
    line-height: 1;
    margin-right: 6px; }
  .mc3-card .card-footer .text-left {
    float: left; }
  .mc3-card .card-footer .text-right {
    width: 35%;
    color: #01aed6;
    float: right; }
  .mc3-card .card-footer .card-category {
    margin-bottom: 4px; }
  .mc3-card .card-footer .text-offer {
    color: #01aed6; }

.mc3-card .mc3-arrow-floating {
  background-color: #ffffff;
  margin-top: -9px;
  margin-bottom: -9px;
  width: 18px;
  height: 18px;
  margin-left: 30px;
  transform: rotate(45deg); }

.mc3-card .mc3-card-title.has-tag {
  line-height: 1.5; }

.mc3-card .mc3-card-title.has-tag {
  display: flex; }
  .mc3-card .mc3-card-title.has-tag .tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #ffffff;
    height: 20px;
    font-size: 10px;
    padding: 0 10px;
    margin-right: 4px;
    white-space: nowrap; }
    .mc3-card .mc3-card-title.has-tag .tag.tag-noti {
      background-color: #FC3B3E; }

@media screen and (min-width: 0\0) {
  .mc3-card-horizontal .card-title::after {
    height: 2rem;
    /*This is different between three text*/ }
  .mc3-card-horizontal .card-text::after {
    height: 1.2rem;
    /*This is different between three text*/ }
  .mc3-card-title-long::after {
    height: 1.5rem;
    /*This is different between three text*/ }
  .mc3-card-horizontal .card-title,
  .mc3-card-horizontal .card-text,
  .mc3-card-title-long {
    position: relative;
    overflow: hidden; }
    .mc3-card-horizontal .card-title::after,
    .mc3-card-horizontal .card-text::after,
    .mc3-card-title-long::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 2rem;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%); } }

.mc3-tab .mc3-nav-full {
  width: 100%;
  border-bottom: 1px inset #e2e2e2;
  margin-bottom: 1rem; }

.mc3-tab .mc3-scroll-nav {
  width: 100%;
  margin-bottom: -1px;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .mc3-tab .mc3-scroll-nav::-webkit-scrollbar {
    display: none; }

.mc3-tab .nav {
  display: block; }

.mc3-tab .nav-item {
  display: inline-block;
  min-width: 80px; }

.mc3-tab .nav-link {
  color: #a6a6a6;
  border-radius: 0;
  text-align: center; }
  @media (max-width: 520px) {
    .mc3-tab .nav-link {
      padding: 8px 10px; } }
  .mc3-tab .nav-link.active {
    background: #ffffff;
    border-bottom: 3px solid #f2545b;
    color: #4a4a4a; }

#mc3-ads-left {
  position: absolute;
  left: 0;
  top: 440px; }

#mc3-ads-right {
  position: absolute;
  right: 0;
  top: 440px; }

@media (max-width: 1199.98px) {
  #mc3-ads-left,
  #mc3-ads-right {
    display: none; } }

.mc3-advertisement-left-right {
  height: 630px;
  width: 120px; }
  .mc3-advertisement-left-right .mc3-advertisement-content {
    height: calc(100% - 20px); }

.mc3-advertisement-footer {
  width: 100%;
  padding: 0 10px; }
  .mc3-advertisement-footer .mc3-advertisement-title {
    height: 34px; }

.mc3-advertisement-center .mc3-advertisement-title {
  height: 24px; }

.mc3-advertisement-center .mc3-advertisement-content {
  min-height: 250px;
  min-width: 300px; }

@media (max-width: 767.98px) {
  .mc3-advertisement-center {
    width: 100%; }
    .mc3-advertisement-center iframe {
      margin: auto; } }

.mc3-advertisement-title {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mc3-advertisement-title span {
    color: #4a4a4a;
    margin: 0; }

.mc3-advertisement-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto; }

iframe {
  display: block;
  border: none; }

.mc3-widget-stories {
  padding: 0.5rem;
  margin: 0 0 1rem 1rem;
  border: 2px solid #e8e8e8;
  width: 300px;
  height: 500px;
  float: right; }

form label.error {
  color: #FC3B3E;
  margin-bottom: 0; }

form .form-control.error {
  color: #FC3B3E;
  border-color: #FC3B3E; }

.mc3-field-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4a4a4a;
  line-height: 1.6; }
  .mc3-field-checkbox .bold {
    color: #f7a433; }
  .mc3-field-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .mc3-field-checkbox input:checked ~ .mc3-checkmark {
      border-color: #f7a433; }
    .mc3-field-checkbox input:checked ~ .mc3-checkmark:after {
      display: block; }
  .mc3-field-checkbox .mc3-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #ffffff;
    border: 1px solid #353535;
    border-radius: 2px; }
    .mc3-field-checkbox .mc3-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 2px;
      top: 2px;
      width: 8px;
      height: 8px;
      background: #f7a433;
      border-radius: 2px; }

.form-group {
  position: relative; }
  .form-group .mc3-icon, .form-group .mc3-icon-right, .form-group .mc3-icon-left {
    position: absolute;
    font-size: 14px;
    top: 6px; }
  .form-group .mc3-icon-right {
    right: 15px; }
  .form-group .mc3-icon-left {
    left: 10px; }
    .form-group .mc3-icon-left ~ input {
      padding-left: 32px; }

.form-control {
  height: 32px;
  font-size: 12px; }

.form-control:focus {
  border-color: #f7a433;
  box-shadow: none; }

.form-control:disabled,
.form-control[readonly] {
  background-color: unset; }

.mc3-input-quantity {
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 4px; }
  .mc3-input-quantity input,
  .mc3-input-quantity textarea {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    margin: 0;
    outline: none; }
  .mc3-input-quantity input[type="button"] {
    -webkit-appearance: button;
    cursor: pointer; }
  .mc3-input-quantity input::-webkit-outer-spin-button,
  .mc3-input-quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .mc3-input-quantity .input-group {
    position: relative;
    width: unset;
    height: 30px;
    display: flex;
    align-items: center; }
  .mc3-input-quantity .input-group input[type="button"] {
    border: 1px solid #f7a433;
    min-width: 1.5rem;
    width: auto;
    transition: all 300ms ease;
    color: #f7a433;
    background-color: #fff;
    border-radius: 2px; }
  .mc3-input-quantity .input-group .button-minus,
  .mc3-input-quantity .input-group .button-plus {
    font-weight: bold;
    height: 1.5rem;
    padding: 0;
    width: 1.5rem;
    position: relative;
    cursor: pointer; }
    .mc3-input-quantity .input-group .button-minus.disable,
    .mc3-input-quantity .input-group .button-plus.disable {
      pointer-events: none;
      color: #ced4da;
      border-color: #ced4da; }
  .mc3-input-quantity .input-group .quantity-field {
    position: relative;
    height: 1.5rem;
    text-align: center;
    width: 2rem;
    display: inline-block;
    font-size: 13px;
    resize: vertical;
    border: none; }
  .mc3-input-quantity input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none; }

.mc3-form-filter {
  display: none;
  background: #ffffff;
  position: absolute;
  top: 34px;
  left: 0;
  min-width: 767px;
  width: 767px;
  height: 360px;
  border-radius: 4px;
  z-index: 10;
  padding: 15px;
  -webkit-box-shadow: 0 2px 4px #d7d7d7;
  -moz-box-shadow: 0 2px 4px #d7d7d7;
  -o-box-shadow: 0 2px 4px #d7d7d7;
  -ms-box-shadow: 0 2px 4px #d7d7d7;
  box-shadow: 0 2px 4px #d7d7d7; }
  @media (max-width: 991px) {
    .mc3-form-filter {
      min-width: 592px;
      width: 592px; } }
  @media (max-width: 767px) {
    .mc3-form-filter {
      height: 100vh; } }
  .mc3-form-filter .mc3-form-filter-content {
    position: relative;
    padding: 0 20px; }
  .mc3-form-filter .mc3-mobile-filter-field {
    padding: 0; }
    @media (max-width: 767px) {
      .mc3-form-filter .mc3-mobile-filter-field {
        height: 25vh;
        overflow-y: auto;
        overflow-x: hidden; } }
    .mc3-form-filter .mc3-mobile-filter-field::-webkit-scrollbar-track {
      border-radius: 6px;
      background: #ffffff; }
    .mc3-form-filter .mc3-mobile-filter-field::-webkit-scrollbar-thumb {
      background: #d7d7d7;
      border-radius: 6px; }
  .mc3-form-filter .mc3-mobile-filter-title {
    width: 100%;
    margin-top: 15px; }
  .mc3-form-filter .mc3-filter-feild {
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto; }
    @media (max-width: 767px) {
      .mc3-form-filter .mc3-filter-feild {
        height: 100%;
        overflow: hidden; } }
    .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check.mc3-border-right {
      border-right: 1px solid #ddd; }
      @media (max-width: 767px) {
        .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check.mc3-border-right {
          border-right: none; } }
    .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check ul {
      list-style: none;
      padding: 0; }
    .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-lable-check {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-check-item {
      position: absolute;
      opacity: 0; }
      .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-check-item + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 1.42;
        color: #4a494a;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-check-item + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        min-width: 11px;
        height: 11px;
        background: white;
        border: 1px solid #353535;
        border-radius: 2px; }
      .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-check-item:checked + label:before {
        background: #f35429;
        border-color: #f35429; }
      .mc3-form-filter .mc3-filter-feild .mc3-filter-feild-check .mc3-check-item:checked + label:after {
        content: '';
        position: absolute;
        left: 1px;
        top: 7px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg); }
    .mc3-form-filter .mc3-filter-feild::-webkit-scrollbar-track {
      border-radius: 6px;
      background: #ffffff; }
    .mc3-form-filter .mc3-filter-feild::-webkit-scrollbar-thumb {
      background: #d7d7d7;
      border-radius: 6px; }
  .mc3-form-filter .mc3-form-filter-control {
    margin-top: 10px;
    padding: 0 20px; }
    @media (max-width: 767px) {
      .mc3-form-filter .mc3-form-filter-control {
        margin-top: 30px; }
        .mc3-form-filter .mc3-form-filter-control .mc3-btn {
          width: 100% !important; } }
    .mc3-form-filter .mc3-form-filter-control .mc3-btn {
      width: 100px;
      height: 30px;
      font-size: 12px;
      line-height: 1.42;
      font-weight: 600; }
      .mc3-form-filter .mc3-form-filter-control .mc3-btn.mc3-btn--grey {
        background: #6a6a6a; }
  .mc3-form-filter .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 10px; }
    .mc3-form-filter .close:hover, .mc3-form-filter .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer; }

@media (max-width: 767px) {
  .mc3-form-mobile-filter {
    border: none !important;
    height: 100%;
    width: 100%;
    min-width: 100%;
    position: fixed !important;
    top: 0px !important;
    padding: 0px !important;
    margin-top: 0px !important; } }

.mc3-btn-filter {
  border: none;
  background: transparent;
  text-align: right;
  min-width: 90px; }
  .mc3-btn-filter:hover {
    opacity: 0.6; }

/* Custom swiper */
.swiper-container {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  height: 260px;
  color: #ffffff; }
  .swiper-container .swiper-slide {
    display: flex; }
  .swiper-container .banner-image {
    position: relative;
    width: 65%;
    background-size: cover;
    background-position: center; }
    .swiper-container .banner-image .image-link {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      transform-origin: bottom left;
      transform: skew(-30deg, 0deg);
      z-index: 6; }
  .swiper-container .banner-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5; }
    .swiper-container .banner-content .banner-caption {
      padding: 6% 0; }
    .swiper-container .banner-content .banner-title {
      line-height: 1.27;
      max-width: 340px;
      max-height: 64px;
      overflow: hidden;
      /*For Chrome*/
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical; }
    .swiper-container .banner-content .banner-description {
      max-width: 320px;
      max-height: 42px;
      overflow: hidden;
      /*For Chrome*/
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical; }
  .swiper-container .banner-overlay {
    position: relative;
    width: 50%;
    background-color: #e06817; }
    .swiper-container .banner-overlay::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: -ms-linear-gradient(right, #f7a433 0%, #e06817 100%);
      background-image: -moz-linear-gradient(right, #f7a433 0%, #e06817 100%);
      background-image: -o-linear-gradient(right, #f7a433 0%, #e06817 100%);
      background-image: -webkit-gradient(linear, right top, left top, color-stop(0, #f7a433), color-stop(100, #e06817));
      background-image: -webkit-linear-gradient(right, #f7a433 0%, #e06817 100%);
      background-image: linear-gradient(to right, #e06817, #f7a433);
      -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      transform-origin: bottom left;
      transform: skew(-30deg, 0deg);
      z-index: 5; }
  .swiper-container .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 30px;
    background: #ffffff;
    opacity: 1;
    margin-right: 5px; }
  .swiper-container .swiper-pagination-bullet-active {
    width: 20px;
    opacity: 1;
    background: #ffffff; }
  .swiper-container .swiper-pagination {
    bottom: 1rem; }

@media (max-width: 480px) {
  .swiper-container {
    height: 200px; }
    .swiper-container .banner-image {
      width: 100%; }
    .swiper-container .banner-content {
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e30000', endColorstr='#000000',GradientType=0 ); }
    .swiper-container .banner-overlay {
      display: none; }
    .swiper-container .swiper-pagination {
      bottom: 15px;
      left: 50%;
      transform: translateX(-25px); }
    .swiper-container .image-link {
      display: none; } }

.mc3-review-table {
  border: 1px solid #dddddd; }
  .mc3-review-table [class^="mc3-t-"] {
    margin-bottom: 0; }
  .mc3-review-table .mc3-review-table-header {
    background-color: #f8f8f8;
    padding: .5rem 1rem;
    border-bottom: 1px solid #dddddd; }
  .mc3-review-table .mc3-review-table-content {
    padding: 0 1rem; }
    .mc3-review-table .mc3-review-table-content .mc3-review-table-content-item {
      display: flex;
      justify-content: space-between;
      padding: .25rem 0; }

.modal-header {
  padding: 0.5rem 1rem;
  border-bottom: hidden; }

.modal-body {
  padding: 0.5rem 2rem 2rem 2rem; }
  .modal-body .img-app {
    width: 64px;
    height: 64px;
    border-radius: 1rem; }
  .modal-body .mc3-popup-title {
    color: #000000;
    text-align: center;
    margin-bottom: 1.5rem; }
  .modal-body .mc3-popup-link {
    color: #f7a433;
    margin-bottom: 1rem; }
  .modal-body .mc3-popup-form .mc3-popup-input {
    margin-bottom: 1rem; }
  .modal-body .mc3-popup-form .mc3-bg-google {
    background-color: #e33330; }
  .modal-body .mc3-popup-form .mc3-bg-facebook {
    background-color: #325a9d; }
  .modal-body .mc3-popup-form .mc3-btn {
    line-height: normal; }
    .modal-body .mc3-popup-form .mc3-btn .icon {
      font-size: 16px;
      vertical-align: middle; }

@media (max-width: 575.98px) {
  .modal-body {
    padding: 0.5rem 1rem 1rem 1rem; }
    .modal-body .mc3-popup-title {
      margin-bottom: 1rem; }
    .modal-body .mc3-popup-form .mc3-popup-input {
      margin-bottom: 0.5rem; } }

.modal-has-widget {
  max-width: 382px; }
  .modal-has-widget .modal-body {
    padding: 0.5rem 1rem 1rem 1rem; }
  .modal-has-widget input {
    line-height: normal;
    font-family: Arial; }

.input-1-digit {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #4a4a4a;
  padding: 0; }

.mc3-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1rem auto; }
  .mc3-pagination *:not(:first-child) {
    margin-left: 0.75rem; }
  .mc3-pagination .mc3-page-link {
    position: relative;
    display: block;
    padding: 0.3rem 0.55rem;
    line-height: 1.25;
    border: 1px solid #d4d4d4;
    border-radius: 1rem; }
    .mc3-pagination .mc3-page-link.disabled {
      color: #a6a6a6;
      pointer-events: none;
      cursor: auto; }
    .mc3-pagination .mc3-page-link.prev-link, .mc3-pagination .mc3-page-link.next-link {
      text-align: center;
      width: 80px; }
  .mc3-pagination .mc3-page-link:hover,
  .mc3-pagination .mc3-page-link.active {
    color: #ffffff;
    border: 1px solid #f7a433;
    background-color: #f7a433; }

@media (max-width: 576px) {
  .mc3-pagination *:not(:first-child) {
    margin-left: 0.5rem; }
  .mc3-page-link.prev-link,
  .mc3-page-link.next-link {
    visibility: hidden;
    width: 40px !important; }
    .mc3-page-link.prev-link::after,
    .mc3-page-link.next-link::after {
      visibility: visible;
      position: absolute;
      padding: 0.3rem 0.75rem;
      line-height: 1.25;
      top: 0;
      left: 0;
      border: 1px solid #d4d4d4;
      border-radius: 1rem; }
    .mc3-page-link.prev-link:hover::after,
    .mc3-page-link.next-link:hover::after {
      background: #f7a433;
      border-color: #f7a433; }
  .mc3-page-link.prev-link::after {
    content: '←'; }
  .mc3-page-link.next-link::after {
    content: '→'; } }

.banner-tab-wrapper {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  height: 250px; }
  .banner-tab-wrapper.banner-tab-wrapper--large {
    height: 450px; }
  .banner-tab-wrapper .background-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover; }
    .banner-tab-wrapper .background-banner::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0; }
  .banner-tab-wrapper .banner-content {
    width: 100%;
    height: 100%; }
    .banner-tab-wrapper .banner-content .mc3-container {
      height: 100%; }
    .banner-tab-wrapper .banner-content .banner-container {
      position: relative;
      height: 100%; }
  .banner-tab-wrapper .banner-tab-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff; }
    .banner-tab-wrapper .banner-tab-title p {
      margin-bottom: 0px; }
  .banner-tab-wrapper .banner-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px; }
    .banner-tab-wrapper .banner-tab-header .banner-link {
      color: #ffffff;
      text-decoration: none; }
    @media (max-width: 767.98px) {
      .banner-tab-wrapper .banner-tab-header {
        flex-wrap: wrap; } }
  .banner-tab-wrapper .mc3-banner-base {
    position: relative;
    top: 0; }
  .banner-tab-wrapper .banner-tab {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2; }
    @media (max-width: 575.98px) {
      .banner-tab-wrapper .banner-tab .nav-pills {
        width: 100%; }
        .banner-tab-wrapper .banner-tab .nav-pills .nav-item-custom {
          width: 50%; }
        .banner-tab-wrapper .banner-tab .nav-pills .nav-link-custom {
          width: 100%; } }
    .banner-tab-wrapper .banner-tab h2 {
      font-size: 30px;
      color: #ffffff;
      font-weight: 300; }
    .banner-tab-wrapper .banner-tab .nav-link-custom {
      border-radius: unset;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0.5rem 1rem;
      min-width: 140px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.37);
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .banner-tab-wrapper .banner-tab .nav-link-custom.active {
        color: #4a4a4a;
        background-color: #ffffff; }
    .banner-tab-wrapper .banner-tab .tab-content-custom {
      width: 100%;
      box-sizing: border-box; }
    .banner-tab-wrapper .banner-tab .banner-form--large {
      height: 300px;
      margin-bottom: 28px; }
  .banner-tab-wrapper .banner-search {
    background-color: rgba(247, 164, 51, 0.6);
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 32px; }
    @media (max-width: 767.98px) {
      .banner-tab-wrapper .banner-search {
        flex-wrap: wrap;
        padding: 10px;
        justify-content: center; } }
    .banner-tab-wrapper .banner-search .search-form-group {
      margin-right: 1rem;
      width: 100%; }
      @media (max-width: 767.98px) {
        .banner-tab-wrapper .banner-search .search-form-group {
          margin-right: 0; } }
      .banner-tab-wrapper .banner-search .search-form-group .form-control {
        border-right: none; }
        .banner-tab-wrapper .banner-search .search-form-group .form-control:focus ~ .mc3-btn-filter {
          border-color: #f7a433;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
    .banner-tab-wrapper .banner-search .mc3-btn-filter {
      border: 1px solid #ced4da;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
      background: #ffffff;
      width: 60px;
      font-size: 12px;
      text-align: right; }
    .banner-tab-wrapper .banner-search .search-form-button {
      display: flex; }
      @media (max-width: 767.98px) {
        .banner-tab-wrapper .banner-search .search-form-button {
          width: 100%; } }
    .banner-tab-wrapper .banner-search .mc3-btn {
      min-width: 100px; }
      .banner-tab-wrapper .banner-search .mc3-btn:hover {
        opacity: 1; }
      .banner-tab-wrapper .banner-search .mc3-btn .mc3-btn-outline, .banner-tab-wrapper .banner-search .mc3-btn .mc3-btn-sm-outline, .banner-tab-wrapper .banner-search .mc3-btn .mc3-btn-round-outline {
        background-color: #f7a433;
        color: #ffffff; }
      @media (max-width: 767.98px) {
        .banner-tab-wrapper .banner-search .mc3-btn {
          width: 100%; } }
      .banner-tab-wrapper .banner-search .mc3-btn:not(:last-child) {
        margin-right: 1rem; }

.mc3-list-tags {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  margin-bottom: 0.5rem; }
  .mc3-list-tags .tags {
    display: flex;
    justify-content: start;
    margin: 0 0.5rem 0.5rem 0; }
    .mc3-list-tags .tags .tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: #ffffff;
      height: 20px;
      font-size: 10px;
      line-height: 1.5;
      padding: 0 10px;
      white-space: nowrap; }
      .mc3-list-tags .tags .tag.tag-noti {
        background-color: #FC3B3E; }
      .mc3-list-tags .tags .tag:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
      .mc3-list-tags .tags .tag:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
    .mc3-list-tags .tags .icon-remove {
      position: relative;
      background-color: #FC3B3E;
      cursor: pointer; }
      .mc3-list-tags .tags .icon-remove::before, .mc3-list-tags .tags .icon-remove::after {
        background-color: currentColor;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center; }
      .mc3-list-tags .tags .icon-remove::before {
        height: 1px;
        width: 50%; }
      .mc3-list-tags .tags .icon-remove::after {
        height: 50%;
        width: 1px; }

.mc3-table-flights-container {
  padding: 0.5rem;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 0 5px 0 rgba(74, 74, 74, 0.2);
  -moz-box-shadow: 0 0 5px 0 rgba(74, 74, 74, 0.2);
  -o-box-shadow: 0 0 5px 0 rgba(74, 74, 74, 0.2);
  -ms-box-shadow: 0 0 5px 0 rgba(74, 74, 74, 0.2);
  box-shadow: 0 0 5px 0 rgba(74, 74, 74, 0.2); }
  .mc3-table-flights-container .mc3-line-dot:before {
    content: "";
    width: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    border-bottom: 1px dotted #dcd8d8; }

.mc3-flight-img {
  width: 32px;
  height: 32px; }
  .mc3-flight-img img {
    border-radius: 10%;
    height: 32px;
    width: 32px; }
  .mc3-flight-img.mc3-flight-multi {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .mc3-flight-img.mc3-flight-multi img {
      height: 16px;
      width: 16px; }
    .mc3-flight-img.mc3-flight-multi.mc3-flight-double {
      flex-direction: row; }
      .mc3-flight-img.mc3-flight-multi.mc3-flight-double img:nth-child(2) {
        align-self: flex-end; }

.mc3-hotel-features {
  background-color: #f8f8f8;
  padding: .5rem 1.5rem; }
  .mc3-hotel-features .row {
    padding: .5rem; }
    .mc3-hotel-features .row:not(:last-child) {
      border-bottom: .5px solid #dddddd; }
  .mc3-hotel-features p {
    margin: 0; }

.mc3-location {
  padding: 0.5rem;
  width: 100%;
  border-radius: 2px;
  background-color: #f8f8f8; }
  .mc3-location .mc3-location-title {
    display: flex;
    align-items: center; }
    .mc3-location .mc3-location-title .icon {
      font-size: 22px; }

_:-ms-fullscreen, :root .clamp-guides-tips-main-card-3-line-title {
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 4.5em;
  margin-right: -1em;
  padding-right: 1em; }
  _:-ms-fullscreen:before, :root .clamp-guides-tips-main-card-3-line-title:before {
    content: '...';
    position: absolute;
    right: 25px;
    bottom: 0; }
  _:-ms-fullscreen:after, :root .clamp-guides-tips-main-card-3-line-title:after {
    content: '';
    position: absolute;
    right: 25px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

_:-ms-fullscreen, :root .clamp-guides-tips-main-card-4-line-text {
  overflow: hidden;
  position: relative;
  max-height: 6.0em;
  margin-right: -1em;
  padding-right: 1.1em; }
  _:-ms-fullscreen:before, :root .clamp-guides-tips-main-card-4-line-text:before {
    content: '...';
    position: absolute;
    right: 10px;
    bottom: 0; }
  _:-ms-fullscreen:after, :root .clamp-guides-tips-main-card-4-line-text:after {
    content: '';
    position: absolute;
    right: 10px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

_:-ms-fullscreen, :root .clamp-guides-tips-sub-card-3-line-title {
  overflow: hidden;
  position: relative;
  max-height: 4.5em;
  margin-right: 0em;
  padding-right: 1em;
  min-height: auto !important; }
  _:-ms-fullscreen:before, :root .clamp-guides-tips-sub-card-3-line-title:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0; }
  _:-ms-fullscreen:after, :root .clamp-guides-tips-sub-card-3-line-title:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title {
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 4.5em;
  margin-right: -1em;
  padding-right: 1em; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title:before {
  content: '...';
  position: absolute;
  right: 25px;
  bottom: 0; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title:after {
  content: '';
  position: absolute;
  right: 25px;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text {
  overflow: hidden;
  position: relative;
  max-height: 6.0em;
  margin-right: -1em;
  padding-right: 1em; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text:before {
  content: '...';
  position: absolute;
  right: 10px;
  bottom: 0; }

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text:after {
  content: '';
  position: absolute;
  right: 10px;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white; }

_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title {
  overflow: hidden;
  position: relative;
  max-height: 4.5em;
  margin-right: 0em;
  padding-right: 1em;
  min-height: unset !important; }

_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0; }

_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white; }

.clamp-guides-tips-main-card-3-line-title:not(*:root) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden; }

.clamp-guides-tips-main-card-4-line-text:not(*:root) {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden; }

.clamp-guides-tips-sub-card-3-line-title:not(*:root) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden; }

.mc3-1-for-1-offer {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 0.5rem; }
  .mc3-1-for-1-offer .mc3-1-for-1-images {
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-size: cover;
    background-position: center center; }
  .mc3-1-for-1-offer .mc3-border-left {
    border-left: 0.5px solid #f6f6f6; }
  .mc3-1-for-1-offer [class^="mc3-t-"] {
    margin-bottom: 0; }
  .mc3-1-for-1-offer .icon {
    vertical-align: middle; }

.mc3-open-time-info {
  background: #f8f8f8;
  padding: 0.5rem 1rem; }
  .mc3-open-time-info [class^="mc3-t-"] {
    margin-bottom: 4px; }
  .mc3-open-time-info .icon {
    vertical-align: middle; }
  .mc3-open-time-info .mc3-facility {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }
    .mc3-open-time-info .mc3-facility img {
      height: 1rem;
      width: 1rem;
      margin-right: 0.25rem; }
    .mc3-open-time-info .mc3-facility span {
      margin-bottom: 0px;
      font-size: 10px; }

.mc3-border-bottom {
  border-bottom: 0.5px solid #ddd; }

.mc3-table-menu {
  max-height: 300px;
  overflow-y: auto;
  background-color: #f8f8f8;
  padding: .5rem; }
  .mc3-table-menu .mc3-menu-header {
    border-bottom: 1px solid #d9d9d9; }
  .mc3-table-menu .mc3-menu-content {
    margin-bottom: .5rem; }
    .mc3-table-menu .mc3-menu-content .col-4 {
      text-align: end; }

ol.list-container {
  padding-left: 2.5rem;
  list-style: none;
  counter-reset: item; }
  ol.list-container li {
    counter-increment: item; }
    ol.list-container li:before {
      position: absolute;
      color: #4a4a4a;
      margin-left: -2.5rem;
      content: counters(item, ".") " ";
      display: inline-block; }
  ol.list-container p.list-title {
    cursor: pointer; }
  ol.list-container.mc3-list-lv2 {
    margin-left: -2.5rem;
    margin-bottom: 2rem; }
  ol.list-container.mc3-list-lv3 {
    padding-left: 3.5rem; }
    ol.list-container.mc3-list-lv3 li::before {
      margin-left: -3.5rem; }
  ol.list-container.mc3-list-lv4 {
    padding-left: 4rem; }
    ol.list-container.mc3-list-lv4 li::before {
      margin-left: -4rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f7a433;
  background-color: #f7a433; }

.mc3-aside-bar {
  border: solid 1px #e2e2e2;
  padding: 0;
  margin: 0;
  flex-direction: column; }
  .mc3-aside-bar .mc3-aside-bar-list {
    height: 48px; }
    .mc3-aside-bar .mc3-aside-bar-list:not(:last-child) .mc3-aside-bar-link-content {
      border-bottom: 1px solid #e2e2e2; }
  .mc3-aside-bar .mc3-aside-bar-link {
    padding: 0;
    margin: 0;
    height: 100%;
    display: block;
    border-left: 4px solid transparent; }
    .mc3-aside-bar .mc3-aside-bar-link.active {
      border-color: #f7a433;
      background: #ededed; }
  .mc3-aside-bar .mc3-aside-bar-link-content {
    height: 100%;
    margin: 0 16px;
    display: flex;
    align-items: center; }
    .mc3-aside-bar .mc3-aside-bar-link-content span {
      font-size: 14px;
      margin-left: 10px;
      margin-bottom: 4px; }
    .mc3-aside-bar .mc3-aside-bar-link-content i {
      font-size: 20px; }

@media (max-width: 991px) {
  .mc3-aside-bar {
    border: unset;
    border-bottom: 1px solid #e2e2e2;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap; }
  .mc3-aside-bar-list {
    height: 40px !important;
    width: 100%; }
  .mc3-aside-bar-link {
    border-left: none !important;
    border-bottom: 4px solid transparent; }
  .mc3-aside-bar-link-content {
    border: none !important;
    margin: 0 10px !important;
    justify-content: center;
    align-items: center; } }

.mc3-profile-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #E5E5E5;
  margin-bottom: 1rem; }
  @media (max-width: 991px) {
    .mc3-profile-header {
      border: none; } }
  .mc3-profile-header .mc3-profile-avatar {
    border: 1px solid #9AACAC;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: relative;
    background-size: cover;
    background-position: center center;
    margin-bottom: 1rem; }
    .mc3-profile-header .mc3-profile-avatar .icon-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      width: 33px;
      position: absolute;
      bottom: 5px;
      right: 0;
      color: #ffffff;
      background: #f7a433;
      border-radius: 50%;
      z-index: 10;
      border: 2px solid #ffffff; }
  .mc3-profile-header .mc3-profile-header-count {
    display: flex;
    justify-content: space-between;
    text-align: center; }
  .mc3-profile-header [class^="mc3-t-"] {
    margin-bottom: 0; }

.mc3-profile-info .mc3-profile-info-text {
  padding: 7px 0; }

.mc3-profile-info .form-group {
  margin-bottom: 0.5rem; }

.mc3-profile-info .icon {
  cursor: pointer; }

.mc3-earn-point-container {
  text-align: center;
  padding: 2.5rem 0; }
  .mc3-earn-point-container p {
    margin-bottom: 0; }
  .mc3-earn-point-container .border-md-left {
    border-left: 1px solid #dee2e6; }
    @media (max-width: 991px) {
      .mc3-earn-point-container .border-md-left {
        border-left: none; } }

.mc3-earn-card-set {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem; }
  .mc3-earn-card-set [class^="mc3-t-"] {
    margin-bottom: 0; }
  .mc3-earn-card-set .form-control {
    margin-bottom: 12px; }
  .mc3-earn-card-set input.form-control {
    border: none;
    border-bottom: 1px solid #dee2e6;
    padding: 0; }
  .mc3-earn-card-set #form-payment {
    display: none; }
  .mc3-earn-card-set #form-success {
    display: none; }
  .mc3-earn-card-set .mc3-card-success {
    position: absolute;
    background-color: #f7a433;
    opacity: 0.9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .mc3-earn-card-set .mc3-card-success .mc3-bg-icon-check {
      background-color: #353535;
      padding-top: 0.5rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%; }

.mc3-table-profile th {
  font-family: OpenSans-Bold;
  font-size: 14px; }

.mc3-table-profile td {
  font-family: OpenSans-Regular;
  font-size: 12px; }

.mc3-table .mc3-th {
  border-top: none; }

.mc3-table .mc3-tr {
  border-top: 1px solid #dee2e6; }
  .mc3-table .mc3-tr .mc3-td {
    vertical-align: middle;
    border-top: none;
    padding-bottom: 0; }

.mc3-table .mr3-tr-extra .mc3-td {
  padding-top: 0;
  border-top: none;
  padding-bottom: 0.75rem; }

.mc3-table .mc3-td-title {
  display: none; }

.mc3-table .mc3-order-img {
  margin: auto 0; }

.mc3-table .mc3-img-responsive {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-position: center center;
  background-size: cover; }

.mc3-table .mc3-order-item-block {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.mc3-table .mc3-order-info {
  margin-left: .5rem; }

@media (max-width: 767px) {
  .mc3-table .mc3-th {
    display: none; }
  .mc3-table .mc3-td {
    display: block;
    padding: 0 .75rem; }
    .mc3-table .mc3-td:last-child {
      margin: .5rem 0; }
  .mc3-table .mc3-td-title {
    display: inline; }
  .mc3-table .mc3-order-item-block {
    padding-top: .75rem; } }
