.banner-tab-wrapper {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  height: 250px;
  &.banner-tab-wrapper--large {
    height: 450px;
  }
  .background-banner{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .banner-content {
    width: 100%;
    height: 100%;
    .mc3-container{
      height: 100%;
    }
    .banner-container {
      position: relative;
      height: 100%;
    }
  }
  .banner-tab-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    p {
      margin-bottom: 0px;
    }
  }
  .banner-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    .banner-link {
      color: $white;
      text-decoration: none;
    }
    @media (max-width: 767.98px) {
      flex-wrap: wrap;
    }
  }
  .mc3-banner-base {
    position: relative;
    top: 0;
  }

  .banner-tab {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    @media (max-width: 575.98px) {
      .nav-pills {
        width: 100%;
        .nav-item-custom {
          width: 50%;
        }
        .nav-link-custom {
          width: 100%;
        }
      }
    }
    h2 {
      font-size: 30px;
      color: $white;
      font-weight: 300;
    }
    .nav-link-custom {
      @extend .mc3-t-semibold-14;
      border-radius: unset;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0.5rem 1rem;
      min-width: 140px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.37);
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        color: $grey-t-default;
        background-color: #ffffff;
      }
    }
    .tab-content-custom {
      width: 100%;
      box-sizing: border-box;
    }
    .banner-form--large {
      height: 300px;
      margin-bottom: 28px;
      //background: $white;
    }
  }

  .banner-search {
    background-color: rgba(247, 164, 51, 0.6);
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    @media (max-width: 767.98px) {
      flex-wrap: wrap;
      padding: 10px;
      justify-content: center;
    }
    .search-form-group {
      margin-right: 1rem;
      width: 100%;
      @media (max-width: 767.98px) {
        margin-right: 0;
      }
      .form-control {
        @extend .mc3-t-12;
        border-right: none;
        &:focus ~ .mc3-btn-filter {
          border-color: $primary;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
      }
    }
    .mc3-btn-filter {
      border: 1px solid #ced4da;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
      background: $white;
      width: 60px;
      font-size: 12px;
      text-align: right;
      //color: $primary;
    }
    .search-form-button {
      display: flex;
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
    .mc3-btn {
      min-width: 100px;
      &:hover {
        opacity: 1;
      }
      .mc3-btn-outline {
        background-color: $primary;
        color: $white;
      }
      @media (max-width: 767.98px) {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}