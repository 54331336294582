#mc3-ads-left {
  position: absolute;
  left: $position-x-advertisement;
  top: $position-y-advertisement;
}
#mc3-ads-right {
  position: absolute;
  right: $position-x-advertisement;
  top: $position-y-advertisement;
}
@media (max-width: 1199.98px) {
  #mc3-ads-left,
  #mc3-ads-right {
    display: none;
  }
}
.mc3-advertisement-left-right {
  height: 630px;
  width: 120px;
  .mc3-advertisement-content {
    height: calc(100% - 20px);
  }
}
.mc3-advertisement-footer {
  width: 100%;
  padding: 0 10px;
  .mc3-advertisement-title {
    height: 34px; 
  }
  .mc3-advertisement-content {
    //max-width: 728px;
  }
}

.mc3-advertisement-center {
  //padding: 0 10px 10px;
  .mc3-advertisement-title {
    height: 24px;
  }
  .mc3-advertisement-content {
    min-height: 250px;
    min-width: 300px;
  }
}

@media (max-width: 767.98px) {
  .mc3-advertisement-center {
    width: 100%;
    iframe {
      margin: auto;
    }
  }
}

.mc3-advertisement-title {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #4a4a4a;
    margin: 0;
  }
}
.mc3-advertisement-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
}

iframe {
  display: block;
  border: none;
}

.mc3-widget-stories {
  padding: 0.5rem;
  margin: 0 0 1rem 1rem;
  border: 2px solid #e8e8e8;
  width: 300px;
  height: 500px;
  float: right;
}