.mc3-table-flights-container {
  padding: 0.5rem;
  margin-bottom: 10px;
  @include box-shadow(0 0 5px 0 rgba(74, 74, 74, 0.2));
  .mc3-line-dot {
    &:before {
      content: "";
      width: 100%;
      position: absolute;
      top: 8px;
      left: 0;
      border-bottom: 1px dotted #dcd8d8;
    }
  }
}
.mc3-flight-img {
  width: 32px;
  height: 32px;
  img {
    border-radius: 10%;
    height: 32px;
    width: 32px;
  }
  &.mc3-flight-multi {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    img {
      height: 16px;
      width: 16px;
    }
    &.mc3-flight-double {
      flex-direction: row;
      img:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
}