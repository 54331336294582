_:-ms-fullscreen, :root .clamp-guides-tips-main-card-3-line-title {
  overflow: hidden;
  position: relative; 
  line-height: 1.5em;
  max-height: 4.5em; 
  margin-right: -1em;
  padding-right: 1em;
	&:before {
		  content: '...';
		  position: absolute;
		  right: 25px;
		  bottom: 0;
	}
	&:after {
		  content: '';
		  position: absolute;
		  right: 25px;
		  width: 1em;
		  height: 1em;
		  margin-top: 0.2em;
		  background: white;
	}
}
_:-ms-fullscreen, :root .clamp-guides-tips-main-card-4-line-text {
	overflow: hidden;
	position: relative;
	max-height: 6.0em;
	margin-right: -1em;
	padding-right: 1.1em;
	&:before {
	  content: '...';
		position: absolute;
		right: 10px;
		bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 10px;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}
}
_:-ms-fullscreen, :root .clamp-guides-tips-sub-card-3-line-title {
	overflow: hidden;
	position: relative;
	max-height: 4.5em;
	margin-right: 0em;
	padding-right: 1em;
	min-height: auto !important;
	&:before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}
}

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title {
  overflow: hidden;
  position: relative; 
  line-height: 1.5em;
  max-height: 4.5em; 
  margin-right: -1em;
  padding-right: 1em;
}
_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title:before {
  content: '...';
  position: absolute;
  right: 25px;
  bottom: 0;
}
_:-moz-tree-row(hover), .clamp-guides-tips-main-card-3-line-title:after {
  content: '';
  position: absolute;
  right: 25px;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text {
	overflow: hidden;
	position: relative;
	max-height: 6.0em;
	margin-right: -1em;
	padding-right: 1em;
}
_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text:before {
		content: '...';
		position: absolute;
		right: 10px;
		bottom: 0;
	}
_:-moz-tree-row(hover), .clamp-guides-tips-main-card-4-line-text:after {
		content: '';
		position: absolute;
		right: 10px;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}

_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title {
	overflow: hidden;
	position: relative;
	max-height: 4.5em;
	margin-right: 0em;
	padding-right: 1em;
	min-height: unset !important;
	}
_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title:before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
	}
_:-moz-tree-row(hover), .clamp-guides-tips-sub-card-3-line-title:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}


.clamp-guides-tips-main-card-3-line-title:not(*:root) {
	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden;
}

.clamp-guides-tips-main-card-4-line-text:not(*:root) {
	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden;
}

.clamp-guides-tips-sub-card-3-line-title:not(*:root) {
	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0 0 1em 0;
  overflow: hidden;
}
