form {
  label.error {
    color: $red-noti;
    margin-bottom: 0;
  }
  .form-control.error {
    color: $red-noti;
    border-color: $red-noti;
  }
}

.mc3-field-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $grey-t-default;
  line-height: 1.6;
  .bold {
    color: $primary;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .mc3-checkmark {
      border-color: $primary;
    }
    &:checked ~ .mc3-checkmark:after {
      display: block;
    }
  }
  .mc3-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: $white;
    border: 1px solid $grey-t-special;
    border-radius: 2px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 2px;
      top: 2px;
      width: 8px;
      height: 8px;
      background: $primary;
      border-radius: 2px;
    }
  }
}

.form-group {
  position: relative;
  .mc3-icon {
    position: absolute;
    font-size: 14px;
    top: 6px;
  }
  .mc3-icon-right {
    @extend .mc3-icon;
    right: 15px;
  }
  .mc3-icon-left {
    @extend .mc3-icon;
    left: 10px;
    & ~ input {
      padding-left: 32px;
    }
  }
}
.form-control {
  height: 32px;
  font-size: 12px;
}
.form-control:focus {
  border-color: $primary;
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: unset;
}
.mc3-input-quantity {
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 4px;
  input,
  textarea {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    margin: 0;
    outline: none;
  }

  input[type="button"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .input-group {
    position: relative;
    width: unset;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .input-group input[type="button"] {
    border: 1px solid $primary;
    min-width: 1.5rem;
    width: auto;
    transition: all 300ms ease;
    color: $primary;
    background-color: #fff;
    border-radius: 2px;
  }

  .input-group .button-minus,
  .input-group .button-plus {
    font-weight: bold;
    height: 1.5rem;
    padding: 0;
    width: 1.5rem;
    position: relative;
    cursor: pointer;
    &.disable {
      pointer-events: none;
      color: #ced4da;
      border-color: #ced4da;
    }
  }

  .input-group .quantity-field {
    position: relative;
    height: 1.5rem;
    text-align: center;
    width: 2rem;
    display: inline-block;
    font-size: 13px;
    resize: vertical;
    border: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
}
