.mc3-form-filter {
  display: none;
  background: $white;
  position: absolute;
  top: 34px;
  left: 0;
  min-width: 767px;
  width: 767px;
  height: 360px;
  border-radius: 4px;
  z-index: 10;
  padding: 15px;
  @include box-shadow(0 2px 4px #d7d7d7);
  @media (max-width: 991px) {
    min-width: 592px;    
    width: 592px;    
  }
  @media (max-width: 767px) {
    height: 100vh;
  }
  .mc3-form-filter-content {
    position: relative;
    padding: 0 20px;
  }
  .mc3-mobile-filter-field {
    padding: 0;
    @media (max-width: 767px) {
      height: 25vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $white;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #d7d7d7;
      border-radius: 6px;
    }
  }
  .mc3-mobile-filter-title {
    width: 100%;
    margin-top: 15px;
  }
  .mc3-filter-feild {
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 767px) {
      height: 100%;
      overflow: hidden;
    }
    .mc3-filter-feild-check {
      &.mc3-border-right {
        border-right: 1px solid #ddd;
        @media (max-width: 767px) {
          border-right: none;
        }
      }
      ul {
        list-style: none;
        padding: 0;
      }
      .mc3-lable-check {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .mc3-check-item {
        position: absolute;
        opacity: 0;
        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 1.42;
          color: #4a494a;
          white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        }
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          min-width: 11px;
          height: 11px;
          background: white;
          border: 1px solid #353535;
          border-radius: 2px;
        }
        &:checked + label:before {
          background: #f35429;
          border-color: #f35429;
        }
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 1px;
          top: 7px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow: 
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $white;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #d7d7d7;
      border-radius: 6px;
    }
  }
  .mc3-form-filter-control {
    margin-top: 10px;
    padding: 0 20px;
    @media (max-width: 767px) {
      margin-top: 30px;
      .mc3-btn {
        width: 100%!important;
      }
    }
    .mc3-btn {
      width: 100px;
      height: 30px;
      font-size: 12px;
      line-height: 1.42;
      font-weight: 600;
      &.mc3-btn--grey {
        background: #6a6a6a;
      }
    }
  }
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 10px;
    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
@media (max-width: 767px) {
  .mc3-form-mobile-filter {
    border: none !important;
    height: 100%;
    width: 100%;
    min-width: 100%;
    position: fixed !important;
    top: 0px !important;
    padding: 0px !important;
    margin-top: 0px !important;
  }
}
.mc3-btn-filter {
  border: none;
  background: transparent;
  text-align: right;
  min-width: 90px;
  &:hover {
    opacity: 0.6;
  }
}