.mc3-hotel-features {
  background-color: $bg-info;
  padding: .5rem 1.5rem; 
  .row {
    padding: .5rem;
    &:not(:last-child) {
      border-bottom: .5px solid #dddddd;
    }
  }
  p {
    margin: 0;
  }
}