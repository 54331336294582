.mc3-1-for-1-offer {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 0.5rem;
  .mc3-1-for-1-images {
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-size: cover;
    background-position: center center;
  }
  .mc3-border-left {
    border-left: 0.5px solid #f6f6f6;
  }
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
  .icon {
    vertical-align: middle;
  }
}
