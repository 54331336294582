.modal-header {
  padding: 0.5rem 1rem;
  border-bottom: hidden;
}

.modal-body {
  padding: 0.5rem 2rem 2rem 2rem;
  .img-app {
    width: 64px;
    height: 64px;
    border-radius: 1rem;
  }
  .mc3-popup-title {
    @extend .mc3-t-semibold-18;
    color: $black;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .mc3-popup-link {
    color: $primary;
    margin-bottom: 1rem;
  }
  .mc3-popup-form {
    .mc3-popup-input {
      margin-bottom: 1rem;
    }
    .mc3-bg-google {
      background-color: #e33330;
    }
    .mc3-bg-facebook {
      background-color: #325a9d;
    }
    // [class^="imc-"]:before,
    // [class*=" imc-"]:before {
    //   vertical-align: middle;
    // }
    .mc3-btn {
      line-height: normal;
      .icon {
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .modal-body {
    padding: 0.5rem 1rem 1rem 1rem;
    .mc3-popup-title {
      margin-bottom: 1rem;
    }
    .mc3-popup-form {
      .mc3-popup-input {
        margin-bottom: 0.5rem;
      }
    }
  }

}

.modal-has-widget {
  max-width: 382px;
  .modal-body {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  input {
    line-height: normal;
    font-family: Arial;
  }
}

.input-1-digit {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid $grey-t-default;
  padding: 0;
}
