//side-bar
.mc3-aside-bar {
  border: solid 1px #e2e2e2;
  padding: 0;
  margin: 0;
  flex-direction: column;
  .mc3-aside-bar-list {
    height: 48px;
    &:not(:last-child) .mc3-aside-bar-link-content {
      border-bottom: 1px solid #e2e2e2;
    }
  }
  .mc3-aside-bar-link {
    padding: 0;
    margin: 0;
    height: 100%;
    display: block;
    border-left: 4px solid transparent;
    &.active {
      border-color: $primary;
      background: #ededed;
    }
  }
  .mc3-aside-bar-link-content {
    height: 100%;
    margin: 0 16px;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin-left: 10px;
      margin-bottom: 4px;
    }
    i {
      font-size: 20px;
    }
  }
}

@media (max-width: 991px) {
  .mc3-aside-bar {
    border: unset;
    border-bottom: 1px solid #e2e2e2;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .mc3-aside-bar-list {
    height: 40px!important;
    width: 100%;
  }
  .mc3-aside-bar-link {
    border-left: none!important;
    border-bottom: 4px solid transparent;
  }
  .mc3-aside-bar-link-content {
    border: none!important;
    margin: 0 10px!important;
    justify-content: center;
    align-items: center;
  }
}

//profile
.mc3-profile-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #E5E5E5;
  margin-bottom: 1rem;
  @media (max-width: 991px) {
    border: none;
  }
  .mc3-profile-avatar {
    border: 1px solid #9AACAC;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: relative;
    background-size: cover;
    background-position: center center;
    margin-bottom: 1rem;
    .icon-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      width: 33px;
      position: absolute;
      bottom: 5px;
      right: 0;
      color: $white;
      background: $primary;
      border-radius: 50%;
      z-index: 10;
      border: 2px solid $white;
    }
  }
  .mc3-profile-header-count {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
}

.mc3-profile-info {
  .mc3-profile-info-text {
    padding: 7px 0;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .icon {
    cursor: pointer;
  }
}
//points
.mc3-earn-point-container {
  text-align: center;
  padding: 2.5rem 0;
  p {
    margin-bottom: 0;
  }
  .border-md-left {
    border-left: 1px solid #dee2e6;
    @media (max-width: 991px) {
      border-left: none;
    }
  }
}
.mc3-earn-card-set {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
  .form-control {
    margin-bottom: 12px;
  }
  input.form-control {
    border: none;
    border-bottom: 1px solid #dee2e6;
    padding: 0;
  }
  #form-payment {
    display: none;
  }
  #form-success {
    display: none;
  }
  .mc3-card-success{
    position: absolute;
    background-color: $primary;
    opacity: 0.9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .mc3-bg-icon-check {
      background-color: $grey-t-special;
      padding-top: 0.5rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
  }
}

.mc3-table-profile {
  th {
    font-family: OpenSans-Bold;
    font-size: 14px;
  }
  td {
    font-family: OpenSans-Regular;
    font-size: 12px;
  }
}
//transaction
.mc3-table {
  .mc3-th {
    border-top: none;
  }
  .mc3-tr {
    border-top: 1px solid #dee2e6;
    .mc3-td {
      vertical-align: middle;
      border-top: none;
      padding-bottom: 0;
    }
  }
  .mr3-tr-extra {
    .mc3-td {
      padding-top: 0;
      border-top: none;
      padding-bottom: 0.75rem;
    }
  }
  .mc3-td-title {
    display: none;
  }
  .mc3-order-img {
    margin: auto 0;
  }
  .mc3-img-responsive {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background-position: center center;
    background-size: cover;
  }
  .mc3-order-item-block {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .mc3-order-info {
    margin-left: .5rem;;
  }
  @media (max-width: 767px) {
    .mc3-th {
      display: none;
    }
    .mc3-td {
      display: block;
      padding: 0 .75rem;
      &:last-child {
        margin: .5rem 0;
      }
    }
    .mc3-td-title {
      display: inline;
    }
    .mc3-order-item-block {
      padding-top: .75rem;
    }
  }
}