.navbar {
  height: 40px;
  padding: 0;
  background-color: $bg-nav-menu;
  &:focus{
    outline: none;
  }
}

.navbar-nav {
  width: 100%;
}

nav {
  @media screen and (min-width: 768px) {
    .nav{
      .nav-item:hover {
        .nav-link::before {
          position: absolute;
          content: "";
          display: block;
          background-color: $white;
          left: calc(50% - 65px);
          width: 130px;
          height: 100%;
          top: 0px;
        }
      }
      .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        span {
          z-index: 1;
        }
      }
      .nav-md-sub {
        position: absolute;
        display: none;
        overflow: hidden;
        opacity: 0;
        height: 0;
        z-index: 2;
        left: 0;
        padding-left: 0;
        width: 100%;
        background-color: $white;
        box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.12);
      }
      .nav-sub-item {
        margin: 1.5rem 1.5rem 1.5rem 0;
      }
    }
  }

  .nav{
    .nav-item:active,
    .nav-item:hover {
      .nav-md-sub {
        display: block;
        opacity: 1;
        height: auto; 
        transition: all 0.3s ease-out;
      }
    }
    .nav-link {
      font-size: 14px;
      color: $grey-t-special;
      cursor: pointer;
      &.first-nav{
        padding-left: 0px;
      }
    }
    .nav-sub-title {
      color: $primary;
      font-weight: 600;
      margin-bottom: 6px;
    }
    .nav-sub-desc {
      font-size: 12px;
    }
    .form-control {
      height: 36px;
      width: 280px !important;
      font-size: 12px;
    }
    .mc3-btn {
      height: 34px;
      width: 100px;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar{
    height: auto;
    background-color: $white;
  }
  .nav-sub-item {
    margin-bottom: 1rem;
    margin-right: 1rem;
    .form-group{
      margin-bottom: 0;
    }
  }
  .navbar-expand-md>.container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .nav-md-sub {
    overflow: hidden;
    opacity: 0;
    height: 0;
    padding-left: 0;
  }
}