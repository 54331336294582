.mc3-open-time-info {
  background: #f8f8f8;
  padding: 0.5rem 1rem;
  [class^="mc3-t-"] {
    margin-bottom: 4px;
  }
  .icon {
    vertical-align: middle;
  }
  .mc3-facility {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    //margin-right: 0.5rem;
    img {
      height: 1rem;
      width: 1rem;
      margin-right: 0.25rem;
    }
    span {
      margin-bottom: 0px;
      font-size: 10px;
    }
  }
}

.mc3-border-bottom {
  border-bottom: 0.5px solid #ddd;
}
