@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
/*
Box shadow
*/
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

/*
Item absolute center
*/
@mixin absolute-center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*
Place holder
*/
@mixin place-holder {
  &::-webkit-input-placeholder {@content;}
  &:-moz-placeholder           {@content;}
  &::-moz-placeholder          {@content;}
  &:-ms-input-placeholder      {@content;}
}

/*
gradient banner black top for all browser
*/
@mixin gradient-black-top {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.89) 8%, rgba(0, 0, 0, 0.88) 9%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e30000', endColorstr='#000000',GradientType=0 );
}
@mixin gradient-blue-banner {
  background-image: -ms-linear-gradient(right, $primary 0%, $primary_seconde 100%);
  background-image: -moz-linear-gradient(right, $primary 0%, $primary_seconde 100%);
  background-image: -o-linear-gradient(right, $primary 0%, $primary_seconde 100%);
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, $primary), color-stop(100, $primary_seconde));
  background-image: -webkit-linear-gradient(right, $primary 0%, $primary_seconde 100%);
  background-image: linear-gradient(to right, $primary_seconde, $primary);
}
@mixin transform-origin-b-l { 
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

/*Background Linear-Gradient*/
@mixin button-hover {
  background: -ms-linear-gradient(right, #4abfb5 0%, #0f83bb 100%);
  background: -moz-linear-gradient(right, #4abfb5 0%, #0f83bb 100%);
  background: -o-linear-gradient(right, #4abfb5 0%, #0f83bb 100%);
  background: -webkit-gradient(linear, right top, left top, color-stop(0, #4abfb5), color-stop(100, #0f83bb));
  background: -webkit-linear-gradient(right, #4abfb5 0%, #0f83bb 100%);
  background: linear-gradient(to left, #4abfb5 0%, #0f83bb 100%);
}

/*Regular is default*/
@mixin mc3-t-x-list {
  $i: 8;
  $headings: 32;
  @while $i <= $headings {
    .mc3-t-#{$i} {
      font-size: $i + px;
      font-family: OpenSans-Regular;
    }
    $i: $i + 2;
  }
}

@mixin mc3-t-semibold-x-list {
  $i: 8;
  $headings: 32;
  @while $i <= $headings {
    .mc3-t-semibold-#{$i} {
      font-size: $i + px;
      font-family: OpenSans-SemiBold;
    }
    $i: $i + 2;
  }
}

@mixin mc3-t-bold-x-list {
  $i: 8;
  $headings: 32;
  @while $i <= $headings {
    .mc3-t-bold-#{$i} {
      font-size: $i + px;
      font-family: OpenSans-Bold;
    }
    $i: $i + 2;
  }
}

@mixin mc3-t-light-x-list {
  $i: 8;
  $headings: 32;
  @while $i <= $headings {
    .mc3-t-light-#{$i} {
      font-size: $i + px;
      font-family: OpenSans-Light;
    }
    $i: $i + 2;
  }
}

@mixin mc3-t-prata-x-list {
  $i: 8;
  $headings: 32;
  @while $i <= $headings {
    .mc3-t-prata-#{$i} {
      font-size: $i + px;
      font-family: Prata-Regular;
    }
    $i: $i + 2;
  }
}

/*Define our mixin:*/
@mixin breakpoint($class) {
  @if $class==xs {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $class==sm {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $class==md {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $class==md-landscape {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $class==lg {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $class==min-md {
    @media (min-width: 1024px) {
      @content;
    }
  }
}


