.mc3-description-detail {
  word-break: break-word;
  line-height: 1.75;
  margin-bottom: 0.5rem;
  &.collapse:not(.show) {
    display: block;
    max-height: 63px;
    overflow: hidden;
  }
  &.collapsing {
    height: 63px;
  }
  a[href] {
    color: $primary;
  }
}

.mc3-btn-share:focus ~ .mc3-popup-share,
.mc3-popup-share:hover {
  display: block;
}
.mc3-popup-share {
  position: absolute;
  right: 0;
  bottom: -55px;
  height: 40px;
  border-radius: 4px;
  background: $white;
  z-index: 30;
  display: none;
  @include box-shadow(0px 0px 10px $grey-t-secondary);
  &::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    background: $white;
    transform: rotate(-45deg);
    top: -6px;
    right: 10px;
    z-index: -1;
    @include box-shadow(0px 0px 5px $grey-t-secondary);
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: .5rem;
    height: 100%;
    background: $white;
    li {
      margin: 0 5px;
    }
  }
  img {
    width: 30px;
    height: 30px;
  }
}

.mc3-card-send-a-love {
  width: 100%;
  height: 300px;
  background-position: center center;
  background-size: cover;
}

/*Temp*/
article {
  line-height: 1.75;
  &.has-widget {
    min-height: 500px;
  }
  img {
    width: 100%;
  }
  a[href] {
    color: $primary;
  }
}
//
.mc3-popup-share {
  .social-share-button{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: .5rem;
    height: 100%;
    background: #fff;
    .ssb-icon {
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
    }
  }
}

.mc3-card-comment {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid #dddddd;
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
  overflow-y: auto;
  max-height: 400px;
  .mc3-card-comment-header {
    margin-bottom: 1rem;
    .mc3-card-comment-avatar {
      width: 40px;
      height: 40px;
      border-radius: 999px;
      background-position: center;
      background-size: contain;
      margin-right: .5rem;
    }
  }
}

.mc3-author {
  margin-bottom: 1rem;
  max-height: 400px;
  .mc3-author-header {
    margin-bottom: 0.5rem;
    .mc3-author-avatar {
      width: 40px;
      height: 40px;
      border-radius: 999px;
      background-position: center;
      background-size: contain;
      margin-right: .5rem;
    }
  }
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
}

