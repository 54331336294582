html, body {
  font-family: "OpenSans-Regular";
  color: $grey-t-default;
  scroll-behavior: smooth;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $bd-input-invisible;
  border-radius: 6px;
  background: $bd-input-invisible;
}

::-webkit-scrollbar-thumb {
  background-color: $grey-t-invisible;
  border-radius: 6px;
}