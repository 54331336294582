ol.list-container {
  padding-left: 2.5rem;
  list-style: none;
  counter-reset: item;
  li {
    counter-increment: item;
    &:before {
      position: absolute;
      color: $grey-t-default;
      margin-left: -2.5rem;
      content: counters(item, ".") " ";
      display: inline-block;
    }
  }
  p.list-title {
    cursor: pointer;
  }
  &.mc3-list-lv2{
    margin-left: -2.5rem;
    margin-bottom: 2rem;
  }
  &.mc3-list-lv3{
    padding-left: 3.5rem;
    li::before {
      margin-left: -3.5rem;
    }
  }
  &.mc3-list-lv4 {
    padding-left: 4rem;
    li::before {
      margin-left: -4rem;
    }
  }
}