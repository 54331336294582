.mc3-review-table {
  [class^="mc3-t-"] {
    margin-bottom: 0;
  }
  border: 1px solid #dddddd;
  .mc3-review-table-header {
    background-color: $bg-info;
    padding: .5rem 1rem;
    border-bottom: 1px solid #dddddd;
  }
  .mc3-review-table-content {
    padding: 0 1rem;
    .mc3-review-table-content-item {
      display: flex;
      justify-content: space-between;
      padding: .25rem 0;
    }
  }
}