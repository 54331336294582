.mc3-btn {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: $primary;
  outline: none;
  border: 1px solid transparent;
  color: $white;
  font-size: 12px;
  position: relative;
  transition: all 0.5s ease;
  transform: translateY(0%);
  font-family: OpenSans-SemiBold;
  white-space: nowrap;
  &:active {
    background-color: $primary-hover !important;
  }
  &:hover {
    opacity: 0.6;
    color: $white;
  }
  // &:disabled,
  // &[disabled]{
  //   background-color: #707070;
  //   color: $white;
  // }
}
.mc3-btn-outline {
  background-color: $white;
  border-color: $primary;
  color: $primary;
  &:hover {
    opacity: 0.6;
    background-color: $primary;
    color: $white;
  }
}
.mc3-btn-sm-outline {
  @extend .mc3-btn-outline;
  padding: 0px;
  .icon {
    font-size: 1rem;
    line-height: 1.95;
  }
}
.mc3-btn-round {
  min-width: 80px;
  width: auto;
  height: 28px;
  border-radius: 100px;
}
.mc3-btn-round-outline {
  @extend .mc3-btn-outline;
  @extend .mc3-btn-round;
}
.mc3-btn-round-dark-outline {
  color: $grey-t-default;
  background-color: $white;
  border-color: $grey-t-default;
  @extend .mc3-btn-round;
}
.mc3-button-group-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  .mc3-button-group-detail-title {
    color: $primary;
    margin-bottom: .5rem;
    padding: 0 .5rem;
  }
  .mc3-button-group-detail-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .5rem;
    *:not(:first-child) {
      margin-left: 10px;
    }
  }
  .mc3-btn {
    height: 28px;
    line-height: 1.3;
  }
  .mc3-btn-sm-outline {
    width: 28px;
  }
  @media (max-width: 767px) {
    .mc3-btn {
      width: 100%;
      &.mc3-btn-sm-outline {
        width: 28px;
      }
    }
  }
  .mc3-group-btn-share {
    position: relative;
  }
}
.mc3-btn-show-more {
  @extend .mc3-t-semibold-12;
  color: $primary;
  &:hover {
    color: $primary;
  }
  .icon {
    vertical-align: middle;
  }
  &[aria-expanded=true] > .more,
  &[aria-expanded=true] > .imc-angle-down {
    display: none;
  }
  &[aria-expanded=false] > .less,
  &[aria-expanded=false] > .imc-angle-up {
    display: none;
  }
}

a.btn, a.mc3-btn{
  cursor: pointer;
}

.mc3-btn-flat {
  border: none;
  min-width: 160px;
  color: white;
  background-color: $primary;
  &::before {
    content: "Expand All";
  }
  &.active::before {
    content: "Collapse All";
  }
}
