.mc3-card-group {
  [class^="col-"] {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.mc3-card-horizontal {
  width: 100%;
  .mc3-horizontal-img{
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    padding-top: 272px;
  }
  @media (min-width: 768px) {
    .mc3-card-horizontal-image {
      padding-right: 0px;
    }
    .mc3-card-horizontal-info {
      padding-left: 0px;
    }
  }
  .card-body {
    padding: 1rem 0.5rem;
  }
  .card-title {
    font-family: "Prata-Regular";
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 1rem;
    /*For Chrome*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card-text {
    line-height: 1.75;
    /*For Chrome*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  @media (min-width: 768px) {
    .card-text {
      height: 96px;
    }
    .card-title {
      height: 108px;
    }
  }
  @media (max-width: 767.98px) {
    .card-text {
      max-height: 96px;
    }
    .card-title {
      max-height: 108px;
    }
  }
  .mc3-arrow-floating {
    background-color: $bg-white;
    margin-left: -10px;
    margin-right: -10px;
    width: 20px;
    height: 20px;
    margin-top: 20%;
    transform: rotate(45deg);
  }
}

.mc3-card {
  &.has-shadow {
    @include box-shadow(0px 0px 5px $bd-input-invisible);
    border: none;
  }
  &.highlight {
    overflow: hidden;
    border: 2px solid $primary;
  }
  // &.highlight .mc3-card-title {
  //   color: $primary;
  // }
  .card-body {
    padding: 0.5rem;
  }
  .card-body.fixed-height {
    height: 88px;
  }
  .mc3-card-img{
    position: relative;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    height: 0;
    padding-top: 160px / 280px * 100%;
    border-radius: 1px 1px 0px 0px;
    .icon-remove {
      position: absolute;
      top: 1px;
      right: 0.25rem;
    }
  }
  .mc3-card-title {
    margin-bottom: 0.25rem;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mc3-card-title-long {
    font-family: "Prata-Regular";
    font-size: 1rem;
    line-height: 1.5;
    height: 72px;
    margin-bottom: 0px;
    /*Temp*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card-sub-title {
    margin-bottom: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .card-content {
    color: $grey-t-secondary;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .card-footer {
    background-color: $bg-white;
    padding: 0.5rem;
    border-top: 1px solid #f5f5f5;
    @extend .mc3-t-10;
    .mc3-icon-card {
      float: left;
      font-size: 1rem;
      line-height: 1;
      margin-right: 6px;
    }
    .text-left{
      @extend .mc3-t-overflow;
      float: left;
    }
    .text-right{
      @extend .mc3-t-overflow;
      width: 35%;
      color: $blue;
      float: right;
    }
    .card-category {
      margin-bottom: 4px;
    }
    .text-offer {
      @extend .mc3-t-overflow;
      color: $blue;
    }
  }
  .mc3-arrow-floating {
    background-color: $bg-white;
    margin-top: -9px;
    margin-bottom: -9px;
    width: 18px;
    height: 18px;
    margin-left: 30px;
    transform: rotate(45deg);
  }
  .mc3-card-title.has-tag {
    line-height: 1.5;
  }
  .mc3-card-title.has-tag {
    display: flex;
    .tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: $white;
      height: 20px;
      font-size: 10px;
      padding: 0 10px;
      margin-right: 4px;
      white-space: nowrap;
      &.tag-noti {
        background-color: $red-noti;
      }
    }
  }
}

//IE9,10
@media screen and (min-width: 0\0) {
  .mc3-card-horizontal .card-title {
    &::after {
      height: 2rem; /*This is different between three text*/
    }
  }
  .mc3-card-horizontal .card-text {
    &::after {
      height: 1.2rem; /*This is different between three text*/
    }
  }
  .mc3-card-title-long {
    &::after {
      height: 1.5rem; /*This is different between three text*/
    }
  }
  .mc3-card-horizontal .card-title,
  .mc3-card-horizontal .card-text,
  .mc3-card-title-long {
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 2rem;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }
}
