/* Custom swiper */
.swiper-container {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  height: 260px;
  color: $white;
  .swiper-slide {
    display: flex;
  }
  .banner-image {
    position: relative;
    width: 65%;
    background-size: cover;
    background-position: center;
    .image-link{
      position: absolute;
      width: 100%;
      height: 100%;
      @include transform-origin-b-l;
      transform: skew(-30deg, 0deg);
      z-index: 6;
    }
  }
  .banner-content {
    position: absolute;
    @include absolute-center;
    z-index: 5;
    .banner-caption {
      padding: 6% 0;
    }
    .banner-title {
      line-height: 1.27;
      max-width: 340px;
      max-height: 64px;
      overflow: hidden;
          /*For Chrome*/
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .banner-description {
      max-width: 320px;
      max-height: 42px;
      overflow: hidden;
          /*For Chrome*/
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
  .banner-overlay {
    position: relative;
    width: 50%;
    //background-color: #007ab5;
    background-color: $primary_seconde;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      @include gradient-blue-banner;
      @include transform-origin-b-l;
      transform: skew(-30deg, 0deg);
      z-index: 5;
    }
  }
  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 30px;
    background: $white;
    opacity: 1;
    margin-right: 5px;
  }
  .swiper-pagination-bullet-active {
    width: 20px;
    opacity: 1;
    background: $white;
  }
  .swiper-pagination {
    bottom: 1rem;
  }
}

@include breakpoint(xs) {
  .swiper-container {
    height: 200px;
    .banner-image {
      width: 100%;
    }
    .banner-content {
      @include gradient-black-top;
    }
    .banner-overlay {
      display: none;
    }
    .swiper-pagination {
      bottom: 15px;
      left: 50%;
      transform: translateX(-25px);
    }
    .image-link {
      display: none;
    }
  }
}
