.mc3-location {
  padding: 0.5rem;
  width: 100%;
  border-radius: 2px;
  background-color: $bg-info;
  .mc3-location-title {
    display: flex;
    align-items: center;
    .icon {
      font-size: 22px;
    }
  }
}
