@media (min-width: 992px)
{
  .mc3-container{
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .mc3-row-carousel {
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

@media (max-width: 991.98px){
  .mc3-container{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .mc3-row-carousel {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 767.98px){
  .mc3-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mc3-row-carousel {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 575.98px){
  .mc3-row-carousel {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.mc3-row-carousel {
  margin-bottom: 1rem;
  &.has-background {
    //background: $bg-carousel;
    background-position: center center;
    background-size: cover;
    border-radius: 8px;
    padding-bottom: 0.5rem;
  }
  .mc3-card {
    margin: 0.5rem;
  }
}

//custom owl-carousel
.owl-carousel {
  .owl-nav {
    button.owl-prev,
    button.owl-next {
      width: 36px;
      height: 36px;
      background: $bg-white;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      top: 40%;
      @include box-shadow(0px 0px 5px $bd-input-invisible);
      &.disabled {
        display: none;
      }
      &:focus, &:hover{
        outline: none;
        @include box-shadow(0px 0px 5px $primary);
        transition: all 0.5s ease;
      }
    }
    button.owl-prev {
      left: -40px;
      .mc3-icon-carousel {
        margin-left: 15px;
        border-left: 1.5px solid $grey-t-invisible;
        border-bottom: 1.5px solid $grey-t-invisible;
      }
      &:focus, &:hover{
        .mc3-icon-carousel {
          border-left: 1.5px solid $primary;
          border-bottom: 1.5px solid $primary;
        }
      }
    }
    button.owl-next {
      right: -40px;
      .mc3-icon-carousel {
        margin-left: 10px;
        border-right: 1.5px solid $grey-t-invisible;
        border-top: 1.5px solid $grey-t-invisible;
      }
      &:focus, &:hover{
        .mc3-icon-carousel {
          border-right: 1.5px solid $primary;
          border-top: 1.5px solid $primary;
        }
      }
    }
    .mc3-icon-carousel {
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
    }
  }
}

// Carousel large
.mc3-carousel-detail-lg {
  width: 100%;
  .mc3-slider-main-item {
    width: 100%;
    padding-top: 160px / 280px * 100%;
    background-position: center center;
    background-size: cover;
  }
  .mc3-slider-select-item,
  .mc3-slider-select-item.slick-slide {
    width: 100%;
    height: 64px;
    background-position: center center;
    background-size: cover;
  }
}

//Carousel small
.mc3-carousel-detail-sm {
  width: 100%;
  .mc3-slider-main-item {
    width: 100%;
    //height: 230px;
    padding-top: 160px / 280px * 100%;
    background-position: center center;
    background-size: cover;
  }
  .mc3-slider-select-item,
  .mc3-slider-select-item.slick-slide {
    width: 100%;
    height: 54px;
    background-position: center center;
    background-size: cover;
  }
}

.slider-nav {
  .slick-list {
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 5px;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e8e8e8;
      border-radius: 999px;
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: #c5c9d3;
      border-radius: 6px;
    }
  }
}

.slider-nav-sm .slick-slide,
.slider-nav-lg .slick-slide {
  cursor: pointer;
  margin: 8px 8px 0 0;
  &.is-active {
    border-bottom: 3px solid $primary;
  }
}
.slick-next {
  right: 0.75rem;
  z-index: 2;
  width: unset;
  height: unset;
  &:before {
    font-family: "mc-icon";
    font-size: 20px;
    content: "\4c";
  }
}
.slick-prev {
  left: 0.75rem;
  z-index: 2;
  width: unset;
  height: unset;
  &:before {
    font-family: "mc-icon";
    font-size: 20px;
    content: "\4d";
  }
}
.slick-slider {
  .slick-disabled {
    display: none!important;
  }
}
