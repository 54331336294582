footer{
  background-color: #243345;
  .mc3-footer-page{
    color: $white;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .mc3-footer-copyright{
    background-color: $white;
  }
  .mc3-flex-justify-center{
    display: flex;
    justify-content: center;
  }
  .mc3-flex-text-center{
    display: flex;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 992px){
  .ml-lg-8{
    margin-left: 4rem !important;
  }
}


.icon-app{
  height: 30px;
}

.span-text-nowrap{
  white-space: nowrap;
}