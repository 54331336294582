/*This line fix bug on Safari*/
.row:before,
.row:after {
  display: none !important;
}

[class^="col-"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

@media (min-width: 1200px){
  .container {
      max-width: 960px;
  }
}

