.mc3-header {
  height: 60px;
  .mc3-logo {
    background-image: url(../../assets/images/Me_Rewards.png);
    height: 2rem;
    min-width: 190px;
    background-size: 190px 32px;
    background-repeat: no-repeat;
  }
  .mc3-icon-search {
    margin-top: 4px;
    font-size: 20px;
    position: absolute;
    padding-left: 0.5rem;
  }
  .mc3-logo-circle {
    //max-width: 2rem;
    max-height: 2rem;
  }
  .imc-arrowdown {
    padding-left: 0.5rem;
    font-size: 0.5rem;
  }
  .nav-link {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    // &:hover {
    //   background-color: $primary-hover;
    // }
    &.last-nav {
      padding-right: 0px;
    }
  }
  .dropdown-item {
    font-size: 0.75rem;
    padding: 6px 1rem;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $primary;
    }
    &:active {
      color: inherit;
    }
    img {
      width: 32px;
      height: 32px;
      padding: 6px;
    }
    .icon {
      padding-right: 0.5rem;
      font-size: 18px;
      line-height: 1;
    }
    .mc3-mark-notification {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-family: OpenSans-Bold;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      color: $white;
      background-color: $red-noti;
      margin-left: 10px;
    }
  }
  .dropdown-menu {
    z-index: 1022;
    min-width: 180px;
    top: -1rem !important;
    @media (max-width: 767.98px) {
      transform: translate3d(-127px, 60px, 0px) !important;
    }
    @media (min-width: 768px) {
      left: 0;
    }
  }
  .mc3-vertical-divider {
    height: 1.5rem;
    overflow: hidden;
    border-left: 1px solid $bd-divider;
  }
  .mc3-icon-left {
    margin-top: 10px;
    font-size: 20px;
  }
  .form-group {
    margin-bottom: 0px;
  }
}

.mc3-user-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mc3-circle-icon {
  display: flex;
  justify-content: center;
  border: 0.5px solid #a6a6a6;
  width: 32px;
  height: 32px;
  margin: 10px 5px 10px 5px;
  border-radius: 50%;
  //padding-top: 3px;
  position: relative;
  .mc3-icon-center {
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    &::before {
      line-height: 1.55;
    }
  }
  .mc3-mark-notification {
    display: inline-flex;
    position: absolute;
    top: -4px;
    right: -4px;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    color: $white;
    border: 1px solid $white;
    background-color: $red-noti;
  }
  .mc3-dot {
    position: absolute;
    height: 10px;
    width: 10px;
    margin-left: 12px;
    margin-top: -4px;
    border: 1px solid $white;
    border-radius: 50%;
    background-color: $red-noti;
  }
  //IE9,10
  @media screen and (min-width: 0\0) {
    .mc3-dot {
      margin-left: 16px;
    }
  }
  @media screen and (min--moz-device-pixel-ratio:0) {
    .mc3-dot {
      margin-left: 21px;
    }
  }
}

.mc3-icon-menuleft {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: 0.5px solid #a6a6a6;
  width: 32px;
  height: 32px;
  margin: 10px 10px 10px 0px;
  border-radius: 50%;
}

@media (max-width: 767.98px) {
  // search input
  .mc3-search-box {
    display: flex;
    justify-content: flex-end;
    .mc3-form-search {
      position: relative;
      display: flex;
      width: 50px;
      overflow: hidden;
      transition: 0.5s ease all;
      &.open {
        width: 100%;
        .form-control {
          padding-left: 32px;
        }
      }
    }
    .form-control {
      //width: 32px;
      height: 32px;
      padding-left: 22px;
      border-radius: 999px;
      font-size: 12px;
      border: 0.5px solid #a6a6a6;
      transition: all 0.5s;
    }
  }
  .mc3-header{
    .nav-link {
      padding: 0.25rem 0rem;
    }
    .mc3-logo {
      min-width: 168px;
      background-size: 168px 28px;
      background-position: center;
    }
  }
}

@media (min-width: 768px) {
  .mc3-search-box {
    display: flex;
    justify-content: normal;
    margin-left: 1.5rem !important;
    .mc3-form-search {
      position: relative;
      display: flex;
      width: 100%;
    }
    .form-control {
      height: 32px;
      padding-left: 36px;
      border-radius: 999px;
      font-size: 12px;
      color: $grey-t-default;
      border: 0.5px solid $bd-input;
      width: 230px;
      @include place-holder {
        color: $grey-t-invisible;
      }
      &:focus {
        color: #4a4a4a;
      }
    }
    .mc3-icon-search {
      color: $grey-t-default;
    }
  }
}
.mc3-dropdown-icon-user {
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}